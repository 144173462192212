import { UserInfo, RtstatisticsEntity } from './interface';

export function getUserInfo(): UserInfo {
	return {
		alarmMessage: 0,
		email: '',
		id: 0,
		language: 0, 
		mobile: '',
		name: '',
		profilePhoto: '',
		receiveMessage: 0,
		role: '',
		stationList: [],
		tempUnit: '℃',
		ticket: '',
		ticketExpired: 0,
	};
}


export function getRtstatistics(): RtstatisticsEntity {
	return {
		equipmentCount: 0,
		stationCount: 0,
		p_uti: 0,
		p_sun: 0,
		p_INV: 0,
		p_load: 0,
		kwh_uti_in: 0,
		kwh_sun: 0,
		kwh_gen: 0,
		kwh_INV_di: 0,
		kwh_INV_chg: 0,
		kwh_load: 0,
		total_kwh_uti_in: 0,
		total_kwh_uti_out: 0,
		total_kwh_sun: 0,
		total_kwh_gen: 0,
		total_kwh_INV_di: 0,
		total_kwh_INV_chg: 0,
		total_kwh_load: 0,
	  }
}
