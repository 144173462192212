export default {
    common: {
        "Account": "账号",
        "Logout": "退出登录",
        "Phone": "手机号",
        "Email": "邮箱",
        "PleaseEnter": "请输入",
        "PleaseChoose": "请选择",
        "Password": "密码",
        "LogOutSucceeded": "登录成功",
        "LoginSucceeded": "登录成功",
        "LoginFailed": "登录失败",
        "SignUp": "注册",
        "Login": "登录",
        "Rmb": "元",
        "HomePage": "首页",
        "Day": "天",
        "Month": "月",
        "Year": "年",
        "Customize": "自定义",
        "Total": "累计",
        "Station": "电站",
        "Equipment": "设备",
        "All": "全部",
        "SwitchLanguageTip": "切换为{name}",
        "Cancel": "取消",
        "Save": "保存",
        "Operation": "操作",
        "Refresh": "刷新",
        "Add": "添加",
        "Update": "修改",
        "Delete": "删除",
        "Release": "释放",
        "Bind": "绑定",
        "Succeeded": "成功",
        "Failed": "失败",
        "RequestErrorTip": "接口请求失败，请联系管理员",
        "SetUp": "设置",
        "UpdateSucceeded": "修改成功",
        "UpdateFailed": "修改失败",
        "RefreshSucceeded": "刷新成功",
        "RefreshFailed": "刷新失败",
        "Emailformaterror": "邮箱格式错误",
        "Phoneformaterror": "手机号格式错误",
        "UnvalidTokenTip": "凭证无效，请重新登录",
        "Close": "关闭",
        "NoDataAvailable": "暂无数据",
        "TheBusinessIsAbnomal": "业务异常",
        "TheCountryIsAbnomal": "国家异常",
        "TheCountryNotExists": "国家不存在",
        "TheProvinceIsAbnomal": "省异常",
        "TheProvinceNotExists": "省不存在",
        "TheCityIsAbnomal": "城市异常",
        "TheCityNotExists": "城市不存在",
        "TheFileIsAbnormal": "文件异常",
        "FileFormatIsIncorrect": "文件格式不正确",
        "ParameterIsIncorrect": "参数错误",
        "UserTokenIsInvalidAccessIsProhibited": "用户令牌无效，禁止访问",
        "NotFound": "未找到",
        "HadDeleted": "已删除",
        "ExistAlready": "已存在",
        "VerificationCodeIsIncorrect": "验证码错误",
        "VerificationCodeIsExpired": "验证码过期",
        "TheAcquisitionOfVerificationCodeIsTooFrequently": "验证码获取过于频繁",
        "TheAccountIsAbnormal": "账号异常",
        "TheAccountWasRegisteredAlready": "账号已注册",
        "TheAccountNotExists": "账号不存在",
        "TheMobileIsAbnormal": "手机号异常",
        "TheMobileWasRegisteredAlready": "手机号已经注册",
        "TheMobileNotExists": "手机号不存在",
        "TheEmailIsAbnormal": "邮箱异常",
        "TheEmailWasRegisteredAlready": "邮箱已注册",
        "TheEmailNotExists": "邮箱不存在",
        "PasswordIsIncorrect": "密码错误",
        "OriginalPasswordIsIncorrect": "原密码错误",
        "ServiceError": "服务错误",
        "TestingForEmail": "邮箱校验中",
        "TestingForMobile": "手机号校验中",
        "Unbound": "未绑定",
        "BindPhone": "绑定手机号",
        "BindSuccess": "绑定成功",
        "BindEmail": "绑定邮箱",
    },
    login: {
        "Welcome": "欢迎!",
        "SystemName": "科创户用储能管理云平台",
    },
    largeScreen: {
        "StationStatistics": "电站统计",
        "DataStatistics": "数据统计",
        "RealtimeAlarm": "实时告警",
        "MapTips": "单击切换区域，双击下钻",
    },
    alarm: {
        "DischargeOvercurrent": "放电过流",
        "OverloadAlarm": "过载报警",
        "BatteryNotConnected": "电池未连接",
        "BatteryUndervoltage": "电池欠压",
        "LowBatteryVoltage": "电池电压低",
        "HighBatteryVoltage": "电池电压高",
        "LowGridFrequency": "电网频率低",
        "HighGridFrequency": "电网频率高",
        "LeakageCurrent": "漏电流",
        "CanCommunicationFailureDuringStartup": "开机can通讯失败",
        "ExternalCTConnectedInversely": "外置CT接反",
        "LowBUSVoltage": "BUS电压低",
        "BusVoltageHigh": "BUS电压高",
        "InverterCurrent": "逆变电流",
        "ChargingOvercurrentAlarm": "充电过流告警",
        "BusVibrationAlarm": "母线震荡告警",
        "Normal": "正常",
        "Alarm": "告警",
        "Fault": "故障",
        "AlarmTriggered": "有告警",
        "NoAlarmDetected": "无告警",
        "AlarmCleared": "是否消除", 
        "OccurrenceTime": "发生时间", 
        "RecoveryTime": "恢复时间", 
        "AlarmStatus": "告警状态",
        "Prefecture": "区域",
        "InAlarm": "告警中",
        "Restored": "已恢复",
    },
    station: {
        "TotalIncome": "总收益",
        "MonthIncome": "月收益",
        "DailyIncome": "日收益",
        "ChargingPower": "充放电功率",
        "MainsPower": "市电功率",
        "PVPower": "光伏功率",
        "LoadPower": "负载功率",
        "TotalChargingCapacity": "总充电量",
        "MonthChargingCapacity": "月充电量",
        "DailyChargingCapacity": "日充电量",
        "TotalDischargingCapacity": "总放电量",
        "MonthDischargingCapacity": "月放电量",
        "DailyDischargingCapacity": "日放电量",
        "TotalPowerGeneration": "总发电量",
        "MonthPowerGeneration": "月发电量",
        "DailyPowerGeneration": "日发电量",

        "Income": "收益",
        "ChargingCapacity": "充电量",
        "DischargingCapacity": "放电量",
        "PowerGeneration": "发电量",

        "UnderConstruction": "在建",
        "Operating": "运行中",
        "Cancelled": "已取消",
        "Outage": "停运",

        "AllStations": "所有电站",
        "Followed": "我的关注",

        "StationName": "电站名称",
        "Prefecture": "区域",
        "Address": "地址",
        "StationStatus": "电站状态",
        "Search": "查询",
        "Reset": "重置",
        "AddStation": "添加电站",
        "ViewAlarms": "查看告警",
        "EditStation": "编辑电站",
        "Follow": "关注电站",
        "Unfollow": "取消关注",
        "StationDetails": "电站详情",
        "SureDelete": "确定删除\"{name}\"吗?",
        "SureRelease": "确定释放设备\"{name}\"吗?",
        "Capacity": "容量",

        "BasicInformation": "基础信息",
        "TimeZone": "时区",
        "LatitudeAndLongitude": "经纬度",
        "MapSelection": "地图选点",
        "StartDate": "启用日期",
        "Currency": "货币",
        "UnitPriceOfElectricity": "电价单价",
        "OwnerInformation": "业主信息",
        "OwnerName": "业主姓名",

        "RealtimeData": "实时数据",
        "ParameterSettings": "参数设置",
        "Owner": "业主",

    },
    equipment: {
        "SelfConsumption": "自发自用",
        "PeakShavingAndValleyFilling": "削峰填谷", 
        "PowerGridPriority": "电网优先",
        "WaitingForBackupPower": "等待备电",

        "EquipmentName": "设备名称",
        "EquipmentType": "设备类型",
        "RealtimePower": "实时功率",
        
        "TotalPowerGeneration": "总发电量",
        "DailyPowerGeneration": "日发电量",

        "AlarmStatus": "告警状态",
        "EventStatus": "事件状态",
        "BindStation": "绑定电站",

        "Communicator": "通讯器",
        "Inverter": "逆变器",
        "ElectricityMeter": "电表",

        "Normal": "正常",
        "MainsOutage": "市电停电", 
        "StormWarning": "风暴警戒", 
        "EmergencyStop": "紧急停止", 
        "DischargetoReserveLimit": "放电到保留限", 
        "ResetSelfcheck": "复位自检",
        "EquipmentFailure": "设备故障",

        "RealtimeData": "实时数据",
        "Grid": "电网",
        "PV": "光伏",
        "Load": "负载",
        "Battery": "电池",
    },
    stationStatisticsParams: {
        "MainsPower": "市电功率",
        "PVPower": "光伏功率",
        "LoadPower": "负载功率",
        "InverterPower": "逆变器功率",
        "DailyInputElectricityofMains": "当日市电口输入电量",
        "DailyOutputElectricityofMains": "当日市电口输出电量",
        "DailyPowerSupplyofSolar": "当日太阳能供电量",
        "DailyPowerSupplyofGenerator": "当日发电机供电量",
        "DailyDischargeCapacityofInverter": "当日逆变器放电量",
        "DailyChargeCapacityofInverter": "当日逆变器充电量",
        "DailyPowerConsumptionofLoad": "当日负荷用电量",
        "TotalInputElectricityofMains": "总市电口输入电量",
        "TotalOutputElectricityofMains": "总市电口输出电量",
        "TotalPowerSupplyofSolar": "总太阳能供电量",
        "TotalPowerSupplyofGenerator": "总发电机供电量",
        "TotalDischargeCapacityofInverter": "总逆变器放电量",
        "TotalChargeCapacityofInverter": "总逆变器充电量",
        "TotalPowerConsumptionofLoad": "总负荷用电量",
        "SOC": "SOC",
        "EnvironmentTemperature": "环境温度",
        "GridIncome": "电网收益",
        "ESSIncome": "储能收益", 
        "TotalIncome": "总收益", 
    },
    equipmentRealtime: {
        "PhaseVoltageofGrid": "电网{name}相电压", 
        "PhaseCurrentofGrid": "电网{name}相电流", 
        "Voltage": "电压", 
        "Current": "电流", 
        "Power": "功率", 
        "Temperature": "温度",
        "PhaseVoltageofLoad": "负载{name}相电压", 
        "PhaseCurrentofLoad": "负载{name}相电流", 
        "PhaseVoltageofInverter": "逆变器{name}相电压", 
        "PhaseCurrentofInverter": "逆变器{name}相电流", 
        "Cell": "单体{num}{type}", 
        "ApparentPowerofInverter": "逆变器视在功率",
        "ActivePowerofInverter": "逆变器有功功率", 
        "ReactivePowerofInverter": "逆变器无功功率", 
    },
    equipmentParams: {
        "WorkingMode": "工作模式",
        "DigitalSignal": "设备开关量",
        "PowerGrid": "电网",
        "PeripheralDevice": "外设",
        "Inverter": "逆变器",
        "RetainSOC": "保留SOC",
        "Weekday": "工作日",
        "Weekend": "周末",
        "PriceStrategy": "电价策略({type})",
        "ValleyPeriod": "谷", 
        "FlatPeriod": "平", 
        "PeakPeriod": "峰", 
        "V": "谷", 
        "F": "平", 
        "P": "峰", 
        "DRMEnable": "DRM使能", 
        "Enable": "使能", 
        "Prohibit": "禁止", 
        "No": "否", 
        "Yes": "是", 
        "ArcDetection": "拉弧检测", 
        "BatteryAlarmFunctionEnable": "电池告警功能使能", 
        "ExternalControlModeEnable": "外部控制模式使能", 
        "FactoryReset": "恢复出厂设置", 
        "ActiveIslandingModeEnable": "主动孤岛使能", 
        "AntiPIDEnable": "防PID使能", 
        "BatteryTemperatureCompensationEnable": "电池温度补偿使能", 
        "BatteryActivationEnable": "电池激活使能", 
        "InsulationImpedanceDetectionEnable": "绝缘阻抗检测使能", 
        "LeakageCurrentDetectionEnable": "漏电流检测使能", 
        "RelaySelfcheckEnable": "继电器自检使能", 
        "AutoPowerOn": "自动开机", 
        "StandardType": "标准类型", 
        "RatedGridVoltage": "额定电网电压", 
        "RatedGridFrequency": "额定电网频率", 
        "GridtoBatteryCharging": "电网为电池充电", 
        "AntireverseFlowFunction": "防逆流功能", 
        "MaximumGridReversePower": "电网最大逆流功率", 
        "PowerFactor": "功率因数", 
        "10MinuteGridVoltage": "10分钟电网电压电", 
        "OngridRestorationTime": "并网恢复时间",  
        "ActivePowerSoftStartRate": "有功软启速率",
        "China": "中国",
        "Germany": "德国",
        "MeteringSensorType": "计量传感器类型", 
        "ExternalCTRatio": "外接CT变比",  
        "SmartMeterAddress": "智能电表地址", 
        "MeterManufacturer": "电表厂家", 
        "BatteryManufacturer": "电池厂家", 
        "BatteryCommunicationMethod": "电池通讯方式", 
        "BatteryType": "电池类型", 
        "MPPTControlMode": "MPPT控制模式", 
        "MPPTParallelMode": "MPPT并联模式", 
        "NumberofMPPTChannels": "MPPT路数", 
        "MPPTPower": "MPPT功率", 
        "MPPTPerturbationStepSize": "MPPT扰动步长", 
        "CVTVoltage": "CVT电压", 
        "NumberofBatteryCellsinParallel": "电池并联数",  
        "BatteryConfiguredCapacity": "电池容量设置", 
        "DischargeCutoffVoltage": "放电截止电压", 
        "ChargeRate": "充电倍率", 
        "EqualizingChargeVoltage": "均充电压点", 
        "FloatChargeVoltage": "浮充电压点", 
        "OvervoltageProtectionPoint": "电池过压保护点", 
        "UndervoltageProtectionPoint": "电池欠压保护点", 
        "MaximumChargingCurrent": "电池最大充电电流",
        "MaximumDischargingCurrent": "电池最大放电电流", 
        "MaximumChargingPower": "电池最大充电功率", 
        "MaximumDischargingPower": "电池最大放电功率", 
        "TemperatureCompensationCoefficient": "电池温度补偿系数", 
        "Non": "无", 
        "SmartMeter": "智能电表", 
        "LeadacidBattery": "铅酸电池", 
        "LithiumBattery": "锂电池", 
        "DCPowerSource": "直流源", 
        "Independent": "独立", 
        "Inparallel": "并联", 
        "SetUpDigitalSignal": "设置设备开关量",
        "SetUpPowerGrid": "设置电网",
        "SetUpPeripheralDevice": "设置外设",
        "SetUpInverter": "设置逆变器",
        "SetUpWorkingMode": "设置工作模式",
    },
};
