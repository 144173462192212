/**
 * 用户类型
 */
export enum UserType {
	/**
	 * 业主
	 */
	Owner = 1,

	/**
	 * 安装商
	 */
	Installer = 2,
}

export interface CustomerInfo {
	nickName: string;
	telPhone: string;
	code: string;
	email: string;
	capacity: number;
	address: string;
}

/**
 * 客户端类型
 */
export enum ClientType {
	/**
	 * app
	 */
	App = 1,

	/**
	 * web
	 */
	Web = 2,
}

/**
 * 登录参数
 */
export interface LoginParams {
	/**
	 * 用户类型
	 */
	userType: UserType;

	/**
	 * 邮箱
	 */
	email?: string;

	/**
	 * 手机号码
	 */
	mobile?: string;

	/**
	 * 密码
	 */
	password: string;

	/**
	 * 客户端类型
	 */
	clientType: ClientType;
}

/**
 * 用户信息
 */
export interface UserInfo {
	alarmMessage: number; 
    email: string;
    id: number;
    language: number; 
    mobile: string;
    name: string;
    profilePhoto: string;
    receiveMessage: number;
    role: string;
    stationList: number[];
    tempUnit: string;
    ticket: string;
    ticketExpired: number;
}

/**
 * 权限点
 */
export interface PermissionsPoint {
	/**
	 * 权限编码
	 */
	code: string;
	/**
	 * 权限名称
	 */
	name: string;
	/**
	 * 权限描述
	 */
	description: string;
}

/**
 * 实时统计数据参数
 */
export interface RtstatisticsParams {
	/**
	 * 国家ID 0:不限制
	 */
	countryId: number;

	/**
	 * 省份ID 0:不限制
	 */
	provinceId: number;

	/**
	 * 城市ID 0:不限制
	 */
	cityId: number;
}

/**
 * 实时统计数据实体
 */
export interface RtstatisticsEntity {
	equipmentCount: number;
	stationCount: number;
	p_uti: number;
	p_sun: number;
	p_INV: number;
	p_load: number;
	kwh_uti_in: number;
	kwh_sun: number;
	kwh_gen: number;
	kwh_INV_di: number;
	kwh_INV_chg: number;
	kwh_load: number;
	total_kwh_uti_in: number;
	total_kwh_uti_out: number;
	total_kwh_sun: number;
	total_kwh_gen: number;
	total_kwh_INV_di: number;
	total_kwh_INV_chg: number;
	total_kwh_load: number;
	[key: string]: number | string | boolean;
}

/**
 * 时间类型
 */
export enum DateType {
	/**
	 * 日
	 */
	day = 1,

	/**
	 * 周
	 */
	week = 7,

	/**
	 * 月
	 */
	month = 30,

	/**
	 * 年
	 */
	year = 365,
}


/**
 * 时间查询步长
 */
export enum SearchStep {
	/**
	 * 天
	 */
	day = 1,

	/**
	 * 月
	 */
	month = 2,

	/**
	 * 年
	 */
	year = 3,
}

/**
 * 大屏查询统计数据参数
 */
export interface TrendProfitParams {
	/**
	 * 国家ID 0:不限制
	 */
	countryId: number;

	/**
	 * 省份ID 0:不限制
	 */
	provinceId: number;

	/**
	 * 城市ID 0:不限制
	 */
	cityId: number;

	/**
	 * 开始时间
	 */
	startTime: string;

	/**
	 * 结束时间
	 */
	endTime: string;

	/**
	 * 步长
	 */
	step: SearchStep;

}


/**
 * 大屏统计数据实体
 */
export interface TrendProfitEntity {
	[key: string]: number | string
}

export interface IAlarmsParams {
	/**
	 * 告警条数
	 */
	limit: number;	

	/**
	 * 国家ID
	 */
	countryId: number;	

	/**
	 * 省份ID
	 */
	provinceId: number;	

	/**
	 * 城市ID
	 */
	cityId: number;	
}

export interface AlarmsParams {
	/**
	 * 每页显示行数
	 */
	pageSize: number;	

	/**
	 * 页码
	 */
	pageIndex: number;	

	/**
	 * 电站ID
	 */
	stationId: number;	

	/**
	 * 设备ID
	 */
	equipmentId: number;	

	/**
	 * 查询开始时间
	 */
	startTime: string;	

	/**
	 * 查询截至时间
	 */
	endTime: string;	

	/**
	 * 查询范围：0不限，1告警中的，2已恢复的
	 */
	range: number;	

	/**
	 * 文本搜索
	 */
	queryText: string;	
}

export enum AlarmCode {
	/**
	 * 放电过流
	 */
	DischargeOvercurrent = 1,
	/**
	 * 过载报警
	 */
	OverloadAlarm = 2,
	/**
	 * 电池未连接
	 */
	BaterryNotConnected = 3,
	/**
	 * 电池欠压
	 */
	BaterryUndervoltage = 4,
	/**
	 * 电池电压低
	 */
	LowBaterryVoltage = 5,
	/**
	 * 电池电压高
	 */
	HighBaterryVoltage = 6,
	/**
	 * 电网频率低
	 */
	LowGridFrequency = 7,
	/**
	 * 电网频率高
	 */
	highGridFrequency = 8,
	/**
	 * 漏电流
	 */
	LeakageCurrent = 9,
	/**
	 * 开机can通讯失败
	 */
	CanCommunicationFailed = 10,
	/**
	 * 外置CT接反
	 */
	ExternalCTConnectedInversely = 11,
	/**
	 * BUS电压低
	 */
	LowBUSVoltage = 12,
	/**
	 * BUS电压高
	 */
	HighBUSVoltage = 13,
	/**
	 * 逆变电流
	 */
	InverterCurrent = 14,
	/**
	 * 充电过流告警
	 */
	ChargeOvercurrentAlarm = 15,
	/**
	 * 母线震荡告警
	 */
	BusVibrationAlarm = 16,
}

export enum AlarmLevel {
	/**
	 * 告警
	 */
	Alarm = 0,
	/**
	 * 故障
	 */
	Fault = 1,
	/**
	 * 保护
	 */
	Protect = 2,
	/**
	 * 预警
	 */
	PreAlarm = 3,
}

export interface AlarmsEntity {
	/**
	 * 电站名称
	 */
	stationName: string;	
	
	/**
	 * 设备名称
	 */	
	equipmentName: string;	
	
	/**
	 * 告警ID
	 */	
	id: number;	
	
	/**
	 * 告警代码
	 */	
	alarmCode: AlarmCode;	
	
	/**
	 * 告警等级
	 */	
	level: number;	
	
	/**
	 * 告警描述
	 */	
	description: string;	
	
	/**
	 * 发生时间
	 */	
	alarmTime: string;	
	
	/**
	 * 恢复时间
	 */	
	recoverTime: string;	
	
	/**
	 * 恢复原因
	 */	
	recoverReason: string;	

}

export interface ProvinceEntity {
	/**
	 * ID
	 */
	id:	number;	
		
	/**
	 * 中文名称
	 */
	zhName: string;	
		
	/**
	 * 英文名称
	 */
	enName: string;	

	/**
	 * 城市
	 */
	citys: CityEntity[];
	
	/**
	 * 电站数量
	 */
	stationCount: number;
}

export interface CityEntity {
	/**
	 * ID
	 */
	id:	number;	
		
	/**
	 * 中文名称
	 */
	zhName: string;	
		
	/**
	 * 英文名称
	 */
	enName: string;	
		
	/**
	 * 代号
	 */
	// prefix: string;
		
	/**
	 * 电站数量
	 */
	stationCount: number;
}

export interface CountryEntity {
	/**
	 * ID
	 */
	id:	number;	
		
	/**
	 * 中文名称
	 */
	zhName: string;	
		
	/**
	 * 英文名称
	 */
	enName: string;	

	/**
	 * ISO
	 */
	iso: string;
		
	/**
	 * 代号
	 */
	prefix: string;
		
	/**
	 * 电站数量
	 */
	stationCount: number;

	/**
	 * 省份
	 */
	provinces?: ProvinceAndCityEntity[];

}

export interface ProvinceAndCityEntity {
	/**
	 * ID
	 */
	id:	number;	
		
	/**
	 * 中文名称
	 */
	zhName: string;	
		
	/**
	 * 英文名称
	 */
	enName: string;	

	/**
	 * 经度
	 */
	latitude: string;
		
	/**
	 * 纬度
	 */
	longitude: string;

	/**
	 * 电站数量
	 */
	stationCount: number;

	citys?: ProvinceAndCityEntity[];
}

/**
 * 大屏-电站统计
 */
export interface StationStatisticEntity {
	/**
	 * 状态
	 */
	status: number;

	/**
	 * 状态描述
	 */
	statusDesc: string;

	/**
	 * 数量
	 */
	count: number;
}





export enum EProgrammeType {
	OwnerShare = 1,
	OwnerAutarky = 2,
	OwnerFinancing = 3,
	InvestOwnerShare = 4,
	InvestFinancingOwnerShare = 5,
}

export interface PvcrRealPriceParams {
	

}

export interface OwnerShareParams {
	province: string
	city: string;
	capacity: number;
	year: number;
	life?: number;
	factor?: number;
	cycles: number;
	depth?: number;
	efficiency?: number;
	inverter?: number;
	kwhType: number;
	volLevel: number;
	yearSta?: number;
	financeScale?: number;
	financeInterest?: number;
	ownerRatio?: number;
	programmeType: EProgrammeType;
	loanTerm?: number;
}


export interface IFinancialEntity {
    /**
     * 装机容量
     */
    capacity: number;
	/**
	 * 动态投资回收周期
	 */
	dynamicsCycle?: number;
	/**
	 * 静态投资回收周期
	 */
	staticCycle?: number;
	/**
	 * 财务内部收益率IRR 
	 */
	incomeIRR?: number;
	ownerIncomeByYear?: number;
	ownerRatio?: number;
	financeScale?: number;
	financeInterest?: number;
	investorIncome?: number;

}

export interface OwnerShareEntity {

}