import dayjs from 'dayjs';
import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable'
import { shallowRef, toRaw, toRef, unref, watch } from 'vue';
import Lodash from 'lodash';

export const usePdf = (
    options: {
        formState: any,
        realPriceData: any,
        realPriceColumns: any,
        strategyData: any,
        // chartRefs: any,
        resultFinanceType: any,
        financialData: any,
        role: any,
    }
) => {

    const fileHref = shallowRef(null);
    const {
        formState,
        strategyData,
        // chartRefs,
        resultFinanceType,
        realPriceData,
        financialData,
        role,
    } = options;

    const chartRefs = [];
    
    // let strategy = [];
    // watch(
    //     () => strategyData.value,
    //     () => {
    //         strategy = strategyData.value;
    //     }
    // );

    const primaryColor = '#113963';
    const secondColor = '#385e85';
    const WIDTH = 297;
    const HEIGHT = 205;
    const PADDING = {
        top: 10,
        left: 10,
        right: 10,
        bottom: 10,
    }
    let start_x = 0;
    let start_y = 0;

    const file_name = '工商业储能收益测算方案';
    let doc: any;

    const initPDF = () => {
        doc = new jsPDF(
            {
                orientation: 'l', // p/portrait:竖着, l/landscape:横着
                // unit: 'mm', // 单位: "pt", "mm", "cm", "m", "in" or "px".
                format: [WIDTH, HEIGHT - 20],  // string 'a4' or [width,height]
                // format: 'a4'
            }
        );
        doc.setProperties({
            title: file_name,
            subject: '',
            author: 'Xiao Wang',
            keywords: 'EMS、Profit',
            creator: 'Xiao Wang',
        });
        // doc.addFont('static/JZ_dhcjf.ttf', 'JZ_dhcjf', 'normal');
        doc.addFont('static/hanyixianyuekaijian.ttf', 'hanyixianyuekaijian', 'normal');
        doc.addFont('static/FangZhengHeiTiJianTi-1.ttf', 'FangZhengHeiTiJianTi', 'normal');  // 只支持ttf格式字体
        doc.setFont('FangZhengHeiTiJianTi');
    };

    const getTextLayout = (
        type: 'ALL' | 'W' | 'H',
        text: string,
        font?: string,
        fontSize?: number,
    ): any => {
        const oldFontSize = doc.getFontSize();
        const oldFont = doc.getFont();
        fontSize && doc.setFontSize(fontSize);
        font && doc.setFont(font);
        const {
            w,
            h
        } = doc.getTextDimensions(text);
        doc.setFontSize(oldFontSize);
        doc.setFont(oldFont.fontName);
        if (type === 'ALL') {
            return { w, h };
        } else if (type === 'W') {
            return w;
        } else if (type === 'H') {
            return h;
        }
    }

    /**
     * 标题
     */
    const createTitle = () => {
        const title = '工商业储能收益测算方案';
        const {
            w: titleWidth,
            h: titleHeight
        } = doc.getTextDimensions(title);
        start_x = (WIDTH - titleWidth) / 2;
        start_y = PADDING.top;
        doc.text(
            title,
            start_x,
            start_y,
            {
                baseline: 'top'
            }
        );
        start_y += titleHeight;
    };

    /**
     * 块头部信息
     */
    const createBlockHeader = (
        options: {
            text: string,
            x: number,
            y: number,
        }
    ) => {
        const {
            text,
            x,
            y,
        } = options;
        doc.setFontSize(14);
        doc.setTextColor(primaryColor);
        doc.text(
            text,
            x,
            y,
            {
                baseline: 'top'
            }
        );
    }

    const createNumberBlock = (
        options: {
            width: number;
            height: number;
            x: number;
            y: number;
            lable: string;
            value: number;
            unit: string;
            labelOffsetY?: number;
            valueOffsetY?: number;
        }
    ) => {
        const {
            x,
            y,
            width,
            height,
            lable,
            value,
            unit,
            labelOffsetY = 0,
            valueOffsetY = 0,
        } = options;
        doc.setTextColor('#FFF');
        doc.setFillColor(primaryColor)
        doc.roundedRect(
            x,
            y,
            width,
            height,
            2,
            2,
            'F'
        );
        doc.setFontSize(12);
        doc.text(
            `${value} ${unit}`,
            x + width / 2,
            y + height / 2 - 1 - labelOffsetY,
            {
                baseline: 'bottom',
                align: 'center'
            }
        );
        doc.setFontSize(10);
        doc.text(
            `${lable}`,
            x + width / 2,
            y + height / 2 + 1 + valueOffsetY,
            {
                baseline: 'top',
                align: 'center'
            }
        );
    }

    const createFieldBlock = (
        options: {
            width: number;
            height: number;
            x: number;
            y: number;
            lable: string;
            value: string;
            unit: string;
            labelOffsetY?: number;
            valueOffsetY?: number;
        }
    ) => {
        const {
            x,
            y,
            width,
            height,
            lable,
            value,
            unit,
        } = options;
        doc.setFontSize(13);
        doc.setTextColor('#FFF');
        const h = getTextLayout('H', lable);
        doc.setFillColor(primaryColor);
        doc.rect(
            x,
            y,
            width,
            height,
            'F'
        );
        doc.text(
            `${lable}`,
            x + width / 2,
            y + height / 2 - h / 2,
            {
                baseline: 'top',
                align: 'center'
            }
        );
        doc.setFillColor(secondColor);
        doc.rect(
            x + width + 1,
            y,
            width,
            height,
            'F'
        );
        doc.setFontSize(12);
        doc.text(
            `${value} ${unit}`,
            x + width + 1 + width / 2,
            y + height / 2 - h / 2,
            {
                baseline: 'top',
                align: 'center'
            }
        );
    }


    /**
     * 创建单个策略
     */
    const createSingleStrategy = (
        options: any,
        i: number
    ) => {
        const {
            // pricingStartDate,
            // pricingEndDate,
            date,
        } = options;
        const title = date;
        // const title = `${dayjs(pricingStartDate).format('YYYY-MM-DD')} ~ ${dayjs(pricingEndDate).format('YYYY-MM-DD')}月`;
        const TEXT_WIDTH = getTextLayout('W', title) as number;
        const h = getTextLayout('H', title) as number;

        // 边框
        start_x = PADDING.left + 15;
        doc.setDrawColor('rgb(209, 217, 222)');
        doc.setLineWidth(0.2);
        doc.roundedRect(
            start_x,
            start_y,
            WIDTH - PADDING.left - PADDING.right - 15 * 2,
            75,
            3,
            3,
            'S'
        );
        doc.setFillColor(primaryColor);
        doc.roundedRect(
            start_x,
            start_y,
            WIDTH - PADDING.left - PADDING.right - 15 * 2,
            12,
            3,
            0,
            'F'
        );
        doc.setFont('FangZhengHeiTiJianTi');
        doc.setFontSize(13);
        doc.setTextColor('#FFF');
        doc.text(
            title,
            WIDTH / 2 - TEXT_WIDTH / 2,
            start_y + 3.5,
            {
                baseline: 'top'
            }
        );
        const Y1 = start_y + h + 10;

        start_y = Y1;
        const mychart = chartRefs[i].mychart;
        const imgData = mychart.getDataURL();
        const chartImg = new Image();
        chartImg.src = imgData;
        doc.addImage({
            imageData: chartImg,
            format: 'PNG',
            x: start_x + 5,
            y: start_y,
            width: WIDTH - PADDING.left - PADDING.right - 15 * 2 - 20,
            height: 60,
        });
        start_y = start_y + 60;
    };

    /**
     * 首页
     */
    const createHomePage = () => {
        doc.addImage({
            imageData: '/static/home_banner.jpeg',
            format: 'JPEG',
            x: 0,
            y: 0,
            width: WIDTH,
            height: HEIGHT,
        });
        doc.addImage({
            imageData: '/static/kc_logo.png',
            format: 'PNG',
            x: PADDING.left,
            y: 5,
            width: 60,
            height: 10,
        });
        const title = '工商业储能收益测算报告';
        const {
            w: titleWidth,
            h: titleHeight
        } = getTextLayout(
            'ALL',
            title,
            'hanyixianyuekaijian',
            40
        );
        doc.setFont('hanyixianyuekaijian');
        doc.setFontSize(40);
        doc.setTextColor('#FFF');
        start_x = (WIDTH - titleWidth) / 2;
        doc.text(
            title,
            start_x,
            50,
            {
                baseline: 'top'
            }
        );
        start_y += titleHeight + 50;

        const title_en = 'ES Revenue Calculation Report';
        doc.setFont('FangZhengHeiTiJianTi');
        doc.setFontSize(20);
        const {
            w: titleEnWidth,
            h: titleEnHeight
        } = getTextLayout(
            'ALL',
            title_en,
            'FangZhengHeiTiJianTi',
            20
        );
        start_x = (WIDTH - titleEnWidth) / 2;
        doc.text(
            title_en,
            start_x,
            start_y + 5,
            {
                baseline: 'top'
            }
        );
        start_y = start_y + 5 + titleEnHeight;

        const title_tip1 = '免责声明：本测算软件的所有信息和测算数据仅供使用者参考，';
        const title_tip2 = '如需装机测算或洽谈业务请联系本公司电话 ：138 24161212 吴工；';
        doc.setFont('FangZhengHeiTiJianTi');
        doc.setFontSize(12);
        const {
            w: titleTipWidth1,
            h: titleTipHeight
        } = getTextLayout(
            'ALL',
            title_tip1,
            'FangZhengHeiTiJianTi',
            12
        );
        start_x = (WIDTH - titleTipWidth1) / 2;
        doc.text(
            title_tip1,
            WIDTH / 2,
            start_y + 7,
            {
                baseline: 'top',
                align: 'center'
            }
        );

        start_y += titleTipHeight + 7;
        const {
            w: titleTipWidth2,
        } = getTextLayout(
            'ALL',
            title_tip2,
            'FangZhengHeiTiJianTi',
            12
        );
        start_x = (WIDTH - titleTipWidth2) / 2;
        doc.text(
            title_tip2,
            WIDTH / 2,
            start_y + 3,
            {
                baseline: 'top',
                align: 'center'
            }
        );

    };


    /**
     * 页头
     */
    const createPageHeader = (
        options: {
            text: string;
        }
    ) => {
        start_x = start_y = 5;
        doc.setFillColor(primaryColor);
        doc.rect(start_x, start_y, 1, 5, 'F');
        doc.setFont('FangZhengHeiTiJianTi');
        doc.setFontSize(14);
        doc.setTextColor(primaryColor);
        const {
            w,
            h,
        } = getTextLayout(
            'ALL',
            options.text,

        );
        doc.text(
            options.text,
            start_x + 3,
            start_y + 2.5 - h / 2,
            {
                baseline: 'top'
            }
        );

        doc.setDrawColor(primaryColor);
        doc.setLineWidth(0.6);
        doc.line(
            start_x + w + 5,
            start_y + 2.5,
            WIDTH - PADDING.right,
            start_y + 2.5,
        );
        start_x = PADDING.left;
        start_y = PADDING.top;
    };


    /**
     * 页尾
     */
    const createPageFooter = (
        num: string
    ) => {
        start_x = start_y = 5;
        doc.setFont('FangZhengHeiTiJianTi');
        doc.setFontSize(10);
        doc.setTextColor(primaryColor);
        doc.text(
            num,
            WIDTH - PADDING.right,
            HEIGHT - PADDING.bottom,
            {
                baseline: 'bottom',
                align: 'right'
            }
        );
        const {
            w,
            h,
        } = getTextLayout(
            'ALL',
            num,

        );
        doc.setDrawColor(primaryColor);
        doc.setLineWidth(0.6);
        doc.line(
            WIDTH - PADDING.right - 60,
            HEIGHT - PADDING.bottom - h / 2,
            WIDTH - PADDING.right - 10,
            HEIGHT - PADDING.bottom - h / 2,
        );
        start_x = PADDING.left;
        start_y = PADDING.top;
    };


    const getVolLevelDesc = (value: number) => {
        const volMap = [
            '不满1KV',
            '10/20千伏',
            '35千伏',
            '110千伏'
        ];
        return volMap[value];
    }

    const getKwhTypeDesc = (value: number) => {
        const volMap = [
            '一般工商业用电',
            '大工业用电',
        ];
        return volMap[value];
    }

    const createOwnerParams = () => {
        const FIELD_RECT_INTERVAL = 3;
        const FIELD_RECT_WIDTH = 50;
        const FIELD_RECT_HEIGHT = 10;
        createFieldBlock(
            {
                x: start_x,
                y: start_y + 10,
                width: FIELD_RECT_WIDTH,
                height: FIELD_RECT_HEIGHT,
                lable: '区域',
                value: formState.value.area,
                unit: '',
            }
        );

        createFieldBlock(
            {
                x: start_x + FIELD_RECT_WIDTH * 2 + 45 + FIELD_RECT_INTERVAL,
                y: start_y + 10,
                width: FIELD_RECT_WIDTH,
                height: FIELD_RECT_HEIGHT,
                lable: '输入电压',
                value: getVolLevelDesc(formState.value.volLevel),
                unit: 'kV',
            }
        );

        start_y = start_y + 10;

        createFieldBlock(
            {
                x: start_x,
                y: start_y + FIELD_RECT_HEIGHT + FIELD_RECT_INTERVAL,
                width: FIELD_RECT_WIDTH,
                height: FIELD_RECT_HEIGHT,
                lable: '用电类型',
                value: getKwhTypeDesc(formState.value.kwhType),
                unit: '',
            }
        );
        createFieldBlock(
            {
                x: start_x + FIELD_RECT_WIDTH * 2 + 45 + FIELD_RECT_INTERVAL,
                y: start_y + FIELD_RECT_HEIGHT + FIELD_RECT_INTERVAL,
                width: FIELD_RECT_WIDTH,
                height: FIELD_RECT_HEIGHT,
                lable: '装机容量',
                value: formState.value.capacity,
                unit: 'MWh',
            }
        );

        start_y = start_y + FIELD_RECT_HEIGHT + FIELD_RECT_INTERVAL;

        createFieldBlock(
            {
                x: start_x,
                y: start_y + FIELD_RECT_HEIGHT + FIELD_RECT_INTERVAL,
                width: FIELD_RECT_WIDTH,
                height: FIELD_RECT_HEIGHT,
                lable: '项目周期',
                value: formState.value.yearSta,
                unit: '年',
            }
        );
        createFieldBlock(
            {
                x: start_x + FIELD_RECT_WIDTH * 2 + 45 + FIELD_RECT_INTERVAL,
                y: start_y + FIELD_RECT_HEIGHT + FIELD_RECT_INTERVAL,
                width: FIELD_RECT_WIDTH,
                height: FIELD_RECT_HEIGHT,
                lable: '投资方案',
                value: '不投资，只享受收益分成',
                unit: '',
            }
        );

        start_y = start_y + FIELD_RECT_HEIGHT + FIELD_RECT_INTERVAL;
    }


    const createOwnerInvestParams = () => {
        const FIELD_RECT_INTERVAL = 3;
        const FIELD_RECT_WIDTH = 50;
        const FIELD_RECT_HEIGHT = 10;
        createFieldBlock(
            {
                x: start_x,
                y: start_y + 10,
                width: FIELD_RECT_WIDTH,
                height: FIELD_RECT_HEIGHT,
                lable: '区域',
                value: formState.value.area,
                unit: '',
            }
        );

        createFieldBlock(
            {
                x: start_x + FIELD_RECT_WIDTH * 2 + 45 + FIELD_RECT_INTERVAL,
                y: start_y + 10,
                width: FIELD_RECT_WIDTH,
                height: FIELD_RECT_HEIGHT,
                lable: '输入电压',
                value: getVolLevelDesc(formState.value.volLevel),
                unit: 'kV',
            }
        );

        start_y = start_y + 10;

        createFieldBlock(
            {
                x: start_x,
                y: start_y + FIELD_RECT_HEIGHT + FIELD_RECT_INTERVAL,
                width: FIELD_RECT_WIDTH,
                height: FIELD_RECT_HEIGHT,
                lable: '用电类型',
                value: getKwhTypeDesc(formState.value.kwhType),
                unit: '',
            }
        );
        createFieldBlock(
            {
                x: start_x + FIELD_RECT_WIDTH * 2 + 45 + FIELD_RECT_INTERVAL,
                y: start_y + FIELD_RECT_HEIGHT + FIELD_RECT_INTERVAL,
                width: FIELD_RECT_WIDTH,
                height: FIELD_RECT_HEIGHT,
                lable: '装机容量',
                value: formState.value.capacity,
                unit: 'MWh',
            }
        );

        start_y = start_y + FIELD_RECT_HEIGHT + FIELD_RECT_INTERVAL;

        createFieldBlock(
            {
                x: start_x,
                y: start_y + FIELD_RECT_HEIGHT + FIELD_RECT_INTERVAL,
                width: FIELD_RECT_WIDTH,
                height: FIELD_RECT_HEIGHT,
                lable: '项目周期',
                value: formState.value.yearSta,
                unit: '年',
            }
        );
        createFieldBlock(
            {
                x: start_x + FIELD_RECT_WIDTH * 2 + 45 + FIELD_RECT_INTERVAL,
                y: start_y + FIELD_RECT_HEIGHT + FIELD_RECT_INTERVAL,
                width: FIELD_RECT_WIDTH,
                height: FIELD_RECT_HEIGHT,
                lable: '投资方案',
                value: '自行投资运营',
                unit: '',
            }
        );

    }

    const createOwnerInvestFinanceParams = () => {
        const FIELD_RECT_INTERVAL = 3;
        const FIELD_RECT_WIDTH = 50;
        const FIELD_RECT_HEIGHT = 10;
        createFieldBlock(
            {
                x: start_x,
                y: start_y + 10,
                width: FIELD_RECT_WIDTH,
                height: FIELD_RECT_HEIGHT,
                lable: '区域',
                value: formState.value.area,
                unit: '',
            }
        );

        createFieldBlock(
            {
                x: start_x + FIELD_RECT_WIDTH * 2 + 45 + FIELD_RECT_INTERVAL,
                y: start_y + 10,
                width: FIELD_RECT_WIDTH,
                height: FIELD_RECT_HEIGHT,
                lable: '输入电压',
                value: getVolLevelDesc(formState.value.volLevel),
                unit: 'kV',
            }
        );

        start_y = start_y + 10;

        createFieldBlock(
            {
                x: start_x,
                y: start_y + FIELD_RECT_HEIGHT + FIELD_RECT_INTERVAL,
                width: FIELD_RECT_WIDTH,
                height: FIELD_RECT_HEIGHT,
                lable: '用电类型',
                value: getKwhTypeDesc(formState.value.kwhType),
                unit: '',
            }
        );
        createFieldBlock(
            {
                x: start_x + FIELD_RECT_WIDTH * 2 + 45 + FIELD_RECT_INTERVAL,
                y: start_y + FIELD_RECT_HEIGHT + FIELD_RECT_INTERVAL,
                width: FIELD_RECT_WIDTH,
                height: FIELD_RECT_HEIGHT,
                lable: '装机容量',
                value: formState.value.capacity,
                unit: 'MWh',
            }
        );

        start_y = start_y + FIELD_RECT_HEIGHT + FIELD_RECT_INTERVAL;

        createFieldBlock(
            {
                x: start_x,
                y: start_y + FIELD_RECT_HEIGHT + FIELD_RECT_INTERVAL,
                width: FIELD_RECT_WIDTH,
                height: FIELD_RECT_HEIGHT,
                lable: '项目周期',
                value: formState.value.yearSta,
                unit: '年',
            }
        );
        createFieldBlock(
            {
                x: start_x + FIELD_RECT_WIDTH * 2 + 45 + FIELD_RECT_INTERVAL,
                y: start_y + FIELD_RECT_HEIGHT + FIELD_RECT_INTERVAL,
                width: FIELD_RECT_WIDTH,
                height: FIELD_RECT_HEIGHT,
                lable: '投资方案',
                value: '自行投资运营',
                unit: '',
            }
        );

        start_y = start_y + FIELD_RECT_HEIGHT + FIELD_RECT_INTERVAL;
        createFieldBlock(
            {
                x: start_x,
                y: start_y + FIELD_RECT_HEIGHT + FIELD_RECT_INTERVAL,
                width: FIELD_RECT_WIDTH,
                height: FIELD_RECT_HEIGHT,
                lable: '融资比例',
                value: Lodash.round(Lodash.multiply(formState.value.financeScale, 100), 2),
                unit: '%',
            }
        );
        createFieldBlock(
            {
                x: start_x + FIELD_RECT_WIDTH * 2 + 45 + FIELD_RECT_INTERVAL,
                y: start_y + FIELD_RECT_HEIGHT + FIELD_RECT_INTERVAL,
                width: FIELD_RECT_WIDTH,
                height: FIELD_RECT_HEIGHT,
                lable: '融资利率',
                value: formState.value.financeInterest,
                unit: '%',
            }
        );


    }

    const createInvestorParams = () => {
        const FIELD_RECT_INTERVAL = 3;
        const FIELD_RECT_WIDTH = 50;
        const FIELD_RECT_HEIGHT = 10;
        createFieldBlock(
            {
                x: start_x,
                y: start_y + 10,
                width: FIELD_RECT_WIDTH,
                height: FIELD_RECT_HEIGHT,
                lable: '区域',
                value: formState.value.area,
                unit: '',
            }
        );

        createFieldBlock(
            {
                x: start_x + FIELD_RECT_WIDTH * 2 + 45 + FIELD_RECT_INTERVAL,
                y: start_y + 10,
                width: FIELD_RECT_WIDTH,
                height: FIELD_RECT_HEIGHT,
                lable: '输入电压',
                value: getVolLevelDesc(formState.value.volLevel),
                unit: 'KV',
            }
        );

        start_y = start_y + 10;

        createFieldBlock(
            {
                x: start_x,
                y: start_y + FIELD_RECT_HEIGHT + FIELD_RECT_INTERVAL,
                width: FIELD_RECT_WIDTH,
                height: FIELD_RECT_HEIGHT,
                lable: '用电类型',
                value: getKwhTypeDesc(formState.value.kwhType),
                unit: '',
            }
        );
        createFieldBlock(
            {
                x: start_x + FIELD_RECT_WIDTH * 2 + 45 + FIELD_RECT_INTERVAL,
                y: start_y + FIELD_RECT_HEIGHT + FIELD_RECT_INTERVAL,
                width: FIELD_RECT_WIDTH,
                height: FIELD_RECT_HEIGHT,
                lable: '装机容量',
                value: formState.value.capacity,
                unit: 'MWh',
            }
        );

        start_y = start_y + FIELD_RECT_HEIGHT + FIELD_RECT_INTERVAL;

        createFieldBlock(
            {
                x: start_x,
                y: start_y + FIELD_RECT_HEIGHT + FIELD_RECT_INTERVAL,
                width: FIELD_RECT_WIDTH,
                height: FIELD_RECT_HEIGHT,
                lable: '项目周期',
                value: formState.value.yearSta,
                unit: '年',
            }
        );
        createFieldBlock(
            {
                x: start_x + FIELD_RECT_WIDTH * 2 + 45 + FIELD_RECT_INTERVAL,
                y: start_y + FIELD_RECT_HEIGHT + FIELD_RECT_INTERVAL,
                width: FIELD_RECT_WIDTH,
                height: FIELD_RECT_HEIGHT,
                lable: '投资方案',
                value: '不融资，业主分成',
                unit: '',
            }
        );

        start_y = start_y + FIELD_RECT_HEIGHT + FIELD_RECT_INTERVAL;

        createFieldBlock(
            {
                x: start_x,
                y: start_y + FIELD_RECT_HEIGHT + FIELD_RECT_INTERVAL,
                width: FIELD_RECT_WIDTH,
                height: FIELD_RECT_HEIGHT,
                lable: '业主分成比例',
                value: formState.value.ownerRatio,
                unit: '%',
            }
        );
    }

    const createInvestorFinanceParams = () => {
        const FIELD_RECT_INTERVAL = 3;
        const FIELD_RECT_WIDTH = 50;
        const FIELD_RECT_HEIGHT = 10;
        createFieldBlock(
            {
                x: start_x,
                y: start_y + 10,
                width: FIELD_RECT_WIDTH,
                height: FIELD_RECT_HEIGHT,
                lable: '区域',
                value: formState.value.area,
                unit: '',
            }
        );

        createFieldBlock(
            {
                x: start_x + FIELD_RECT_WIDTH * 2 + 45 + FIELD_RECT_INTERVAL,
                y: start_y + 10,
                width: FIELD_RECT_WIDTH,
                height: FIELD_RECT_HEIGHT,
                lable: '输入电压',
                value: getVolLevelDesc(formState.value.volLevel),
                unit: 'kV',
            }
        );

        start_y = start_y + 10;

        createFieldBlock(
            {
                x: start_x,
                y: start_y + FIELD_RECT_HEIGHT + FIELD_RECT_INTERVAL,
                width: FIELD_RECT_WIDTH,
                height: FIELD_RECT_HEIGHT,
                lable: '用电类型',
                value: getKwhTypeDesc(formState.value.kwhType),
                unit: '',
            }
        );
        createFieldBlock(
            {
                x: start_x + FIELD_RECT_WIDTH * 2 + 45 + FIELD_RECT_INTERVAL,
                y: start_y + FIELD_RECT_HEIGHT + FIELD_RECT_INTERVAL,
                width: FIELD_RECT_WIDTH,
                height: FIELD_RECT_HEIGHT,
                lable: '装机容量',
                value: formState.value.capacity,
                unit: 'MWh',
            }
        );

        start_y = start_y + FIELD_RECT_HEIGHT + FIELD_RECT_INTERVAL;

        createFieldBlock(
            {
                x: start_x,
                y: start_y + FIELD_RECT_HEIGHT + FIELD_RECT_INTERVAL,
                width: FIELD_RECT_WIDTH,
                height: FIELD_RECT_HEIGHT,
                lable: '项目周期',
                value: formState.value.yearSta,
                unit: '年',
            }
        );
        createFieldBlock(
            {
                x: start_x + FIELD_RECT_WIDTH * 2 + 45 + FIELD_RECT_INTERVAL,
                y: start_y + FIELD_RECT_HEIGHT + FIELD_RECT_INTERVAL,
                width: FIELD_RECT_WIDTH,
                height: FIELD_RECT_HEIGHT,
                lable: '投资方案',
                value: '融资，业主分成',
                unit: '',
            }
        );

        start_y = start_y + FIELD_RECT_HEIGHT + FIELD_RECT_INTERVAL;

        createFieldBlock(
            {
                x: start_x,
                y: start_y + FIELD_RECT_HEIGHT + FIELD_RECT_INTERVAL,
                width: FIELD_RECT_WIDTH,
                height: FIELD_RECT_HEIGHT,
                lable: '业主分成比例',
                value: formState.value.ownerRatio,
                unit: '%',
            }
        );
        createFieldBlock(
            {
                x: start_x + FIELD_RECT_WIDTH * 2 + 45 + FIELD_RECT_INTERVAL,
                y: start_y + FIELD_RECT_HEIGHT + FIELD_RECT_INTERVAL,
                width: FIELD_RECT_WIDTH,
                height: FIELD_RECT_HEIGHT,
                lable: '融资利率',
                value: formState.value.financeInterest,
                unit: '%',
            }
        );

        start_y = start_y + FIELD_RECT_HEIGHT + FIELD_RECT_INTERVAL;
        createFieldBlock(
            {
                x: start_x,
                y: start_y + FIELD_RECT_HEIGHT + FIELD_RECT_INTERVAL,
                width: FIELD_RECT_WIDTH,
                height: FIELD_RECT_HEIGHT,
                lable: '融资比例',
                value: Lodash.round(Lodash.multiply(formState.value.financeScale, 100), 2),
                unit: '%',
            }
        );
        
    }

    

    /**
     * 参数页
     */
    const createParamsPage = () => {
        const FIELD_RECT_INTERVAL = 3;
        const FIELD_RECT_WIDTH = 50;
        const FIELD_RECT_HEIGHT = 10;

        doc.setFontSize(16);
        doc.setFont('FangZhengHeiTiJianTi');
        start_x = PADDING.left + 15;
        start_y = start_y + 10;
        createBlockHeader(
            {
                text: '角色',
                x: start_x,
                y: start_y
            }
        );
        doc.setFontSize(14);
        doc.setTextColor('#333');
        start_y += 10;
        doc.text(
            role.value === 0 ? '业主' : '投资方',
            start_x,
            start_y,
            {
                baseline: 'top'
            }
        );

        doc.setFontSize(16);
        doc.setFont('FangZhengHeiTiJianTi');
        start_x = PADDING.left + 15;
        start_y = start_y + 20;
        createBlockHeader(
            {
                text: '基础参数',
                x: start_x,
                y: start_y
            }
        );

        if (resultFinanceType.value === 0) {
            createOwnerParams();
        } else if(resultFinanceType.value === 1) {
            createOwnerInvestParams();
        } else if(resultFinanceType.value === 2) {
            createOwnerInvestFinanceParams();
        } else if(resultFinanceType.value === 3) {
            createInvestorParams();
        } else if(resultFinanceType.value === 4) {
            createInvestorFinanceParams();
        }

        start_y = start_y + FIELD_RECT_HEIGHT + FIELD_RECT_INTERVAL;

        doc.setFont('FangZhengHeiTiJianTi');
        createBlockHeader(
            {
                text: '分时电价',
                x: start_x,
                y: start_y + 20
            }
        );
        start_y = start_y + 20;

        doc.setFont('FangZhengHeiTiJianTi');

        const bodyData = [];
        for (let i = 0; i < realPriceData.value.length; i++) {
            bodyData.push(
                [
                    realPriceData.value[i].date,
                    realPriceData.value[i].j,
                    realPriceData.value[i].f,
                    realPriceData.value[i].p,
                    realPriceData.value[i].g
                ]
            );
        }
        autoTable(
            doc,
            {
                tableWidth: FIELD_RECT_WIDTH * 4 + 45 + FIELD_RECT_INTERVAL,
                useCss: true,
                showHead: 'everyPage',
                margin: {
                    top: start_y + 10,
                    left: start_x,
                },
                head: [['用电季节', '尖（元）', '峰（元）', '平（元）', '谷（元）']],
                body: bodyData,
                styles: {
                    font: 'FangZhengHeiTiJianTi',
                    lineColor: [209, 217, 222],
                    lineWidth: 0.2,
                    halign: 'center',
                    textColor: '#333',
                    cellPadding: { vertical: 2.71 }
                },
                headStyles: {
                    fillColor: [17, 57, 99],
                    fontSize: 13,
                    textColor: '#FFF',
                },
                bodyStyles: {
                    fillColor: [255, 255, 255],
                    textColor: 0,
                    fontSize: 13
                },
                // alternateRowStyles: {
                //     fillColor: [155, 183, 214],
                // },
            }
        );


    };

    /**
     * 财务指标汇总
     */
    const createMoneySummary = () => {
        const FIELD_RECT_INTERVAL = 3;
        const FIELD_RECT_WIDTH = 50;
        const FIELD_RECT_HEIGHT = 10;
        doc.setFontSize(16);
        doc.setFont('FangZhengHeiTiJianTi');
        const title = '财务指标汇总';
        start_x = PADDING.left + 15;
        start_y = start_y + 10;
        createBlockHeader(
            {
                text: title,
                x: start_x,
                y: start_y
            }
        );
        const h = getTextLayout('H', title) as number;

        if (resultFinanceType.value === 0) {
            createFieldBlock(
                {
                    x: start_x,
                    y: start_y + 10,
                    width: FIELD_RECT_WIDTH,
                    height: FIELD_RECT_HEIGHT,
                    lable: '10年业主收益',
                    value: financialData.value.ownerIncomeByYear,
                    unit: '万元',
                }
            );
    
            createFieldBlock(
                {
                    x: start_x + FIELD_RECT_WIDTH * 2 + 45 + FIELD_RECT_INTERVAL,
                    y: start_y + 10,
                    width: FIELD_RECT_WIDTH,
                    height: FIELD_RECT_HEIGHT,
                    lable: '业主分成比例',
                    value: financialData.value.ownerRatio,
                    unit: '%',
                }
            );
        } else if(resultFinanceType.value === 1) {
            
            createFieldBlock(
                {
                    x: start_x,
                    y: start_y + 10,
                    width: FIELD_RECT_WIDTH,
                    height: FIELD_RECT_HEIGHT,
                    lable: '10年业主收益',
                    value: financialData.value.ownerIncomeByYear,
                    unit: '万元',
                }
            );
    
            createFieldBlock(
                {
                    x: start_x + FIELD_RECT_WIDTH * 2 + 45 + FIELD_RECT_INTERVAL,
                    y: start_y + 10,
                    width: FIELD_RECT_WIDTH,
                    height: FIELD_RECT_HEIGHT,
                    lable: '财务内部收益率IRR',
                    value: financialData.value.incomeIRR,
                    unit: '%',
                }
            );

            start_y = start_y + 10;

            createFieldBlock(
                {
                    x: start_x,
                    y: start_y + FIELD_RECT_HEIGHT + FIELD_RECT_INTERVAL,
                    width: FIELD_RECT_WIDTH,
                    height: FIELD_RECT_HEIGHT,
                    lable: '静态投资回收周期',
                    value: financialData.value.staticCycle > 10 ? "超过10" : financialData.staticCycle,
                    unit: '年',
                }
            );
    
            // createFieldBlock(
            //     {
            //         x: start_x + FIELD_RECT_WIDTH * 2 + 45 + FIELD_RECT_INTERVAL,
            //         y: start_y + FIELD_RECT_HEIGHT + FIELD_RECT_INTERVAL,
            //         width: FIELD_RECT_WIDTH,
            //         height: FIELD_RECT_HEIGHT,
            //         lable: '动态投资回收周期',
            //         value: financialData.value.dynamicsCycle,
            //         unit: '年',
            //     }
            // );

        } else if(resultFinanceType.value === 2) {
            createFieldBlock(
                {
                    x: start_x,
                    y: start_y + 10,
                    width: FIELD_RECT_WIDTH,
                    height: FIELD_RECT_HEIGHT,
                    lable: '10年业主收益',
                    value: financialData.value.ownerIncomeByYear,
                    unit: '万元',
                }
            );
    
            createFieldBlock(
                {
                    x: start_x + FIELD_RECT_WIDTH * 2 + 45 + FIELD_RECT_INTERVAL,
                    y: start_y + 10,
                    width: FIELD_RECT_WIDTH,
                    height: FIELD_RECT_HEIGHT,
                    lable: '财务内部收益率IRR',
                    value: financialData.value.incomeIRR,
                    unit: '%',
                }
            );

            start_y = start_y + 10;
            // createFieldBlock(
            //     {
            //         x: start_x,
            //         y: start_y + FIELD_RECT_HEIGHT + FIELD_RECT_INTERVAL,
            //         width: FIELD_RECT_WIDTH,
            //         height: FIELD_RECT_HEIGHT,
            //         lable: '融资比例',
            //         value: financialData.value.financeScale,
            //         unit: '%',
            //     }
            // );
            // createFieldBlock(
            //     {
            //         x: start_x + FIELD_RECT_WIDTH * 2 + 45 + FIELD_RECT_INTERVAL,
            //         y: start_y + FIELD_RECT_HEIGHT + FIELD_RECT_INTERVAL,
            //         width: FIELD_RECT_WIDTH,
            //         height: FIELD_RECT_HEIGHT,
            //         lable: '融资利率',
            //         value: financialData.value.financeInterest,
            //         unit: '%',
            //     }
            // );

            // start_y = start_y + FIELD_RECT_HEIGHT + FIELD_RECT_INTERVAL;
            createFieldBlock(
                {
                    x: start_x,
                    y: start_y + FIELD_RECT_HEIGHT + FIELD_RECT_INTERVAL,
                    width: FIELD_RECT_WIDTH,
                    height: FIELD_RECT_HEIGHT,
                    lable: '静态投资回收周期',
                    value: financialData.value.staticCycle > 10 ? "超过10" : financialData.value.staticCycle,
                    unit: '年',
                }
            );
    
            // createFieldBlock(
            //     {
            //         x: start_x + FIELD_RECT_WIDTH * 2 + 45 + FIELD_RECT_INTERVAL,
            //         y: start_y + FIELD_RECT_HEIGHT + FIELD_RECT_INTERVAL,
            //         width: FIELD_RECT_WIDTH,
            //         height: FIELD_RECT_HEIGHT,
            //         lable: '动态投资回收周期',
            //         value: financialData.value.dynamicsCycle,
            //         unit: '年',
            //     }
            // );
            
        } else if(resultFinanceType.value === 3) {
            createFieldBlock(
                {
                    x: start_x,
                    y: start_y + 10,
                    width: FIELD_RECT_WIDTH,
                    height: FIELD_RECT_HEIGHT,
                    lable: '10年投资方收益',
                    value: financialData.value.investorIncome,
                    unit: '万元',
                }
            );
    
            createFieldBlock(
                {
                    x: start_x + FIELD_RECT_WIDTH * 2 + 45 + FIELD_RECT_INTERVAL,
                    y: start_y + 10,
                    width: FIELD_RECT_WIDTH,
                    height: FIELD_RECT_HEIGHT,
                    lable: '财务内部收益率IRR',
                    value: financialData.value.incomeIRR,
                    unit: '%',
                }
            );

            start_y = start_y + 10;

            createFieldBlock(
                {
                    x: start_x,
                    y: start_y + FIELD_RECT_HEIGHT + FIELD_RECT_INTERVAL,
                    width: FIELD_RECT_WIDTH,
                    height: FIELD_RECT_HEIGHT,
                    lable: '静态投资回收周期',
                    value: financialData.value.staticCycle > 10 ? "超过10" : financialData.value.staticCycle,
                    unit: '年',
                }
            );
    
            // createFieldBlock(
            //     {
            //         x: start_x + FIELD_RECT_WIDTH * 2 + 45 + FIELD_RECT_INTERVAL,
            //         y: start_y + FIELD_RECT_HEIGHT + FIELD_RECT_INTERVAL,
            //         width: FIELD_RECT_WIDTH,
            //         height: FIELD_RECT_HEIGHT,
            //         lable: '动态投资回收周期',
            //         value: financialData.value.dynamicsCycle,
            //         unit: '年',
            //     }
            // );
        } else if(resultFinanceType.value === 4) {
            createFieldBlock(
                {
                    x: start_x,
                    y: start_y + 10,
                    width: FIELD_RECT_WIDTH,
                    height: FIELD_RECT_HEIGHT,
                    lable: '10年投资方收益',
                    value: financialData.value.investorIncome,
                    unit: '万元',
                }
            );
    
            createFieldBlock(
                {
                    x: start_x + FIELD_RECT_WIDTH * 2 + 45 + FIELD_RECT_INTERVAL,
                    y: start_y + 10,
                    width: FIELD_RECT_WIDTH,
                    height: FIELD_RECT_HEIGHT,
                    lable: '财务内部收益率IRR',
                    value: financialData.value.incomeIRR,
                    unit: '%',
                }
            );

            start_y = start_y + 10;
            

            // createFieldBlock(
            //     {
            //         x: start_x,
            //         y: start_y + FIELD_RECT_HEIGHT + FIELD_RECT_INTERVAL,
            //         width: FIELD_RECT_WIDTH,
            //         height: FIELD_RECT_HEIGHT,
            //         lable: '融资比例',
            //         value: financialData.value.financeScale,
            //         unit: '%',
            //     }
            // );
            // createFieldBlock(
            //     {
            //         x: start_x + FIELD_RECT_WIDTH * 2 + 45 + FIELD_RECT_INTERVAL,
            //         y: start_y + FIELD_RECT_HEIGHT + FIELD_RECT_INTERVAL,
            //         width: FIELD_RECT_WIDTH,
            //         height: FIELD_RECT_HEIGHT,
            //         lable: '融资利率',
            //         value: financialData.value.financeInterest,
            //         unit: '%',
            //     }
            // );


            // start_y = start_y + FIELD_RECT_HEIGHT + FIELD_RECT_INTERVAL;
            createFieldBlock(
                {
                    x: start_x,
                    y: start_y + FIELD_RECT_HEIGHT + FIELD_RECT_INTERVAL,
                    width: FIELD_RECT_WIDTH,
                    height: FIELD_RECT_HEIGHT,
                    lable: '静态投资回收周期',
                    value: financialData.value.staticCycle > 10 ? "超过10" : financialData.value.staticCycle,
                    unit: '年',
                }
            );
    
            // createFieldBlock(
            //     {
            //         x: start_x + FIELD_RECT_WIDTH * 2 + 45 + FIELD_RECT_INTERVAL,
            //         y: start_y + FIELD_RECT_HEIGHT + FIELD_RECT_INTERVAL,
            //         width: FIELD_RECT_WIDTH,
            //         height: FIELD_RECT_HEIGHT,
            //         lable: '动态投资回收周期',
            //         value: financialData.value.dynamicsCycle,
            //         unit: '年',
            //     }
            // );

        }

        start_y = start_y + FIELD_RECT_HEIGHT + FIELD_RECT_INTERVAL;
       
    }


    /**
     * 充放电策略分析
     */
    const createStrategy = () => {
        const title = '充放电策略分析';
        doc.setFontSize(16);
        doc.setFont('FangZhengHeiTiJianTi');
        start_x = PADDING.left + 15;
        start_y = start_y + 30;
        createBlockHeader(
            {
                text: title,
                x: start_x,
                y: start_y,
            }
        );
        const h = getTextLayout('H', title) as number;
        start_y = start_y + h;
        for (let i = 0; i < strategyData.value.length; i++) {
            if (i === 1) {
                addPage('03', '测算结果');
                start_y = PADDING.top + 10;
                start_x = PADDING.left;
            } else if (i === 3) {
                addPage('04', '测算结果');
                start_y = PADDING.top + 10;
                start_x = PADDING.left;
            } else if (i === 0) {
                start_y = start_y + 6;
            } else {
                start_y = start_y + 15;
            }
            createSingleStrategy(
                strategyData.value[i],
                i
            );
        }
        start_x = PADDING.left + 15;

        doc.setFont('FangZhengHeiTiJianTi');
        doc.setFontSize(12);
        doc.setTextColor('#333');
    };

    const createCompanyPage = () => {
        doc.addImage({
            imageData: '/static/kortrong.png',
            format: 'PNG',
            x: 0,
            y: 0,
            width: WIDTH,
            height: HEIGHT - 20,
        });
    };

    const createPageBase = (num: string, pageTitle: string) => {
        start_y = PADDING.top;
        start_x = PADDING.left;
        createPageHeader({ text: pageTitle });
        createPageFooter(num);
    }

    const addPage = (num: string, pageTitle: string) => {
        doc.addPage([WIDTH, HEIGHT]);
        createPageBase(num, pageTitle);
    }
    

    const downloadPdf = () => {
        initPDF();
        createHomePage();
        addPage('01', '参数配置');
        createParamsPage();
        addPage('02', '测算结果');
        createMoneySummary();
        createStrategy();
        doc.addPage([WIDTH, HEIGHT - 20]);
        createCompanyPage();
        doc.save(file_name);
    };

    return {
        fileHref,
        downloadPdf,
        chartRefs
    }
}