import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router';
import store from '@/store';

import Home from '@/views/Home.vue';
import Login from '@/views/Login.vue';
import NotFound from '@/views/NotFound.vue';
import LargeScreen from '@/views/LargeScreen/index.vue';
import Station from '@/views/Station/index.vue';
import Equipment from '@/views/Equipment/index.vue';
import Alarm from '@/views/Alarm/index.vue';

const routes: Array<RouteRecordRaw> = [
	{
		path: '/',
		name: 'Home',
		component: Home,
	},
	// {
	// 	path: '/:pathMatch(.*)',
	// 	name: 'NotFound',
	// 	component: NotFound,
	// },
];

const router = createRouter({
	history: createWebHashHistory(),
	routes,
});

// router.beforeEach((to, from, next) => {
// 	if (to.name === 'Login') {
// 		if (!store.getters.isAuth) {
// 			next();
// 		} else if (from.name) {
// 			next(false);
// 		} else {
// 			next('/');
// 		}
// 	} else {
// 		if (!store.getters.isAuth) {
// 			next('/login');
// 		} else {
// 			next();
// 		}
// 	}
// });

export default router;
