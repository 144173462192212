import { LauncherError } from '@~crazy/launcher';

import * as DefaultResponse from '@/api/defaultResponse';
import { launcher } from '@/api';
import * as Interface from '@/api/Interface';
// import * as Api from '@/api';
import { Store } from '..';
import { message } from 'ant-design-vue';

const state = {
	...DefaultResponse.getUserInfo(),
	userLanguage: 'en',
	/**
	 * 用户权限
	 */
	permissions: [] as Interface.PermissionsPoint[],
};

type State = typeof state;

const getters = {
	/**
	 * 是否已授权
	 */
	isAuth(state: State): boolean {
		return !!state.ticket;
	},
};

const mutations = {
	/**
	 * 设置授权数据
	 */
	setAuthData(state: State, value: Interface.UserInfo): void {
		state.id = value.id;
		state.name = value.name;
		state.ticket = value.ticket;
		state.userLanguage = 'en';
		launcher.setAuthorization('admin');
		// state.ticket = value.ticket;
		// launcher.setAuthorization(state.ticket);
	},
	/**
	 * 清空授权数据
	 */
	clearAuthData(state: State): void {
		const { id, name, ticket } = DefaultResponse.getUserInfo();
		state.id = id;
		state.name = name;
		state.ticket = ticket;
		launcher.setAuthorization('');
	},
	/**
	 * 设置权限
	 * @param permissions
	 */
	setPermissions(
		state: State,
		permissions: Interface.PermissionsPoint[]
	): void {
		state.permissions = permissions;
	},

	/**
	 * 语言
	 * @param language zh / en
	 */
	SwitchLanguage(
		state: State,
		language: string
	) {
		state.userLanguage = language;
	}
};

const actions = {
	/**
	 * 登录
	 */
	Login(store: Store, params: Interface.LoginParams) {
		return new Promise(async (resolve, reject) => {
			// Api.User.Login(params)
			// 	.then(async (res) => {
			// 		if (res.code === 200) {
			// 			message.success('Login Succeeded');
			// 			store.commit('setAuthData', res.data);
			// 			resolve(res);
			// 		}
			// 	})
			// 	.catch((err: LauncherError) => {
			// 		store.commit('setAuthData', err.options.defaultResponse);
			// 		reject(err);
			// 	});
		});
	},
	/**
	 * 注销
	 */
	Logout({ commit }: Store) {
		commit('clearAuthData');
		commit('setPermissions', []);
		return Promise.resolve();
	},
	/**
	 * 获取权限
	 */
	GetPermissions({ commit }: Store, userId: number) {
		return new Promise((resolve, reject) => {
			// Api.User.getUserPermissions(userId)
			// 	.then((res) => {
			// 		commit('setPermissions', res.data);
			// 		resolve(res);
			// 	})
			// 	.catch((err: LauncherError) => {
			// 		commit('setPermissions', err.options.defaultResponse);
			// 		reject(err);
			// 	});
		});
	},
	/**
	 * 切换语言
	 */
	SwitchLanguage({ commit }: Store, language: string) {
		commit('SwitchLanguage', language);
	},
};

export default {
	state,
	getters,
	mutations,
	actions,
};
