import { launcher, Interface, DefaultResponse } from '..';


/**
 * 获取电价地区
 * @param params 参数
 */
export function GetPvcrPriceAreaList() {
	return launcher.get(
		'/EmsWebService/GetPvcrPriceAreaList',
		{},
		
	);
}

/**
 * 业主方：不投资，只享受收益分成
 * @param params 参数
 */
export function GetOwnerShare(params: Interface.OwnerShareParams) {
	return launcher.post<
		Interface.IFinancialEntity,
		Interface.OwnerShareParams
	>(
		'/EmsWebService/GetOwnerShare',
		params,
		{
			defaultResponse: [],
		}
	);
}


/**
 * 业主方：业主自营不融资
 * @param params 参数
 */
export function GetOwnerAutarky(params: Interface.OwnerShareParams) {
	return launcher.post<
		Interface.IFinancialEntity,
		Interface.OwnerShareParams
	>(
		'/EmsWebService/GetOwnerAutarky',
		params,
		{
			defaultResponse: [],
		}
	);
}


/**
 * 业主方：业主自营且融资
 * @param params 参数
 */
export function GetOwnerAutarkyAndFinancing(params: Interface.OwnerShareParams) {
	return launcher.post<
		Interface.IFinancialEntity,
		Interface.OwnerShareParams
	>(
		'/EmsWebService/GetOwnerAutarkyAndFinancing',
		params,
		{
			defaultResponse: [],
		}
	);
}


/**
 * 投资方：投资方不融资，业主分成
 * @param params 参数
 */
export function GetInvestorNotFinancing(params: Interface.OwnerShareParams) {
	return launcher.post<
		Interface.IFinancialEntity,
		Interface.OwnerShareParams
	>(
		'/EmsWebService/GetInvestorNotFinancing',
		params,
		{
			defaultResponse: [],
		}
	);
}

/**
 * 投资方：投资方融资，业主分成
 * @param params 参数
 */
export function GetInvestorFinancing(params: Interface.OwnerShareParams) {
	return launcher.post<
		Interface.IFinancialEntity,
		Interface.OwnerShareParams
	>(
		'/EmsWebService/GetInvestorFinancing',
		params,
		{
			defaultResponse: [],
		}
	);
}


/**
 * 获取10年收益
 * @param params 参数
 */
export function GetDecadeIncomeCount(params: Interface.OwnerShareParams) {
	return launcher.post<
		Interface.OwnerShareEntity[],
		Interface.OwnerShareParams
	>(
		'/EmsWebService/GetDecadeIncomeCount',
		params,
		{
			defaultResponse: [],
		}
	);
}



/**
 * 获取电价地区
 * @param params 参数
 */
export function GetPvcrRealPrice(
	params: Interface.PvcrRealPriceParams
) {
	return launcher.post<
		any,
		Interface.PvcrRealPriceParams
	>(
		'/EmsWebService/GetPvcrRealPrice',
		params,
	);
}


/**
 * 保存访客信息
 * @param params 参数
 */
export function SaveCustomerInfo(
	params: Interface.CustomerInfo
) {
	return launcher.post<
		any,
		Interface.CustomerInfo
	>(
		'/EmsWebService/SaveCustomerInfo',
		params,
	);
}



/**
 * 获取大屏统计数据
 * @param params 参数
 */
export function GetTrend(params: Interface.TrendProfitParams) {
	return launcher.post<
		Interface.TrendProfitEntity[],
		Interface.TrendProfitParams
	>(
		'/home/trend',
		params,
		{
			defaultResponse: [],
		}
	);
}


/**
 * 获取告警
 * @param params 参数
 */
export function GetAlarms(params: Interface.IAlarmsParams) {
	return launcher.post<
		Interface.AlarmsEntity[],
		Interface.IAlarmsParams
	>(
		'/home/alarms',
		params,
		{
			defaultResponse: [],
		}
	);
}

/**
 * 获取国家列表
 */
export function GetCountries() {
	return launcher.get<
		Interface.CountryEntity[],
		{}
	>(
		'/home/countries',
		{},
		{
			defaultResponse: [],
		}
	);
}

/**
 * 获取省份列表
 */
export function GetProvinces(params: { countryId: number }) {
	return launcher.get<
		Interface.ProvinceAndCityEntity[],
		{ countryId: number }
	>(
		'/home/provinces',
		params,
		{
			defaultResponse: [],
		}
	);
}

/**
 * 获取城市列表
 */
export function GetCites(params: { countryId: number, provinceId: number }) {
	return launcher.get<
		Interface.ProvinceAndCityEntity[],
		{ countryId: number, provinceId: number }
	>(
		'/home/cities',
		params,
		{
			defaultResponse: [],
		}
	);
}

/**
 * 获取电站统计
 */
export function GetStationStatistic(params: Interface.RtstatisticsParams) {
	return launcher.get<
		Interface.StationStatisticEntity[],
		Interface.RtstatisticsParams
	>(
		'/home/status',
		params,
		{
			defaultResponse: [],
		}
	);
}
