import { CustomerInfo, IFinancialEntity, OwnerShareParams } from "@/api/interface";
import { GetDecadeIncomeCount, GetInvestorFinancing, GetInvestorNotFinancing, GetOwnerAutarky, GetOwnerAutarkyAndFinancing, GetOwnerShare, GetPvcrPriceAreaList, GetPvcrRealPrice, SaveCustomerInfo } from "@/api/package/home";
import { CascaderProps } from "ant-design-vue";
import dayjs from "dayjs";
import { Ref, ref } from "vue";
import { FormState } from "./useForm";
import Lodash from 'lodash';


export const useScheme = () => {

    const financialData = ref<IFinancialEntity>({
        capacity: 0,
        dynamicsCycle: 0,
        staticCycle: 0,
        incomeIRR: 0,
        ownerIncomeByYear: 0,
        investorIncome: 0,
        ownerRatio: 0,
        financeScale: 0,
        financeInterest: 0,
    });


    const transformUnit = (sourceData: IFinancialEntity) => {
        const data: IFinancialEntity = {
            capacity: 0,
            dynamicsCycle: 0,
            staticCycle: 0,
            incomeIRR: 0,
            ownerIncomeByYear: 0,
            ownerRatio: 0,
            financeScale: 0,
            financeInterest: 0,
        };
        for (let key in sourceData) {
            if (key === 'ownerIncomeByYear' || key === 'investorIncome') {
                data[key] = Lodash.round(Lodash.divide(sourceData[key] , 10000), 2);
            } else if (key === 'ownerRatio' || key === 'incomeIRR'|| key === 'financeInterest'|| key === 'financeScale') {
                data[key] = Lodash.round(Lodash.multiply(sourceData[key] , 100), 2);
            } else {
                data[key] = typeof sourceData[key] === 'number' ? Lodash.round(sourceData[key], 2) : sourceData[key];
            }
        }
        return data;
    };

    const getOwnerShareData = async (
        params: OwnerShareParams
        ) => {
        try {
            const result: any = await GetOwnerShare(
                params
            );
            if (result.result) {
                financialData.value = {
                    ...transformUnit(result.returnValue),
                    capacity: Lodash.divide(params.capacity, 1000)
                };
            }
        } catch (error) {
            throw error;
        }
    };

    const getOwnerAutarkyData = async (
        params: OwnerShareParams
        ) => {
        try {
            const result: any = await GetOwnerAutarky(
                params
            );
            if (result.result) {
                financialData.value = {
                    ...transformUnit(result.returnValue),
                    capacity: Lodash.divide(params.capacity, 1000)
                };
            }
        } catch (error) {
            throw error;
        }
    };

    const getOwnerAutarkyAndFinancingData = async (
        params: OwnerShareParams
        ) => {
        try {
            const result: any = await GetOwnerAutarkyAndFinancing(
                params
            );
            if (result.result) {
                financialData.value = {
                    ...transformUnit(result.returnValue),
                    capacity: Lodash.divide(params.capacity, 1000)
                };
            }
        } catch (error) {
            throw error;
        }
    };

    const getInvestorNotFinancingData = async (
        params: OwnerShareParams
        ) => {
        try {
            const result: any = await GetInvestorNotFinancing(
                params
            );
            if (result.result) {
                financialData.value = {
                    ...transformUnit(result.returnValue),
                    capacity: Lodash.divide(params.capacity, 1000)
                };
            }
        } catch (error) {
            throw error;
        }
    };

    const getInvestorFinancingData = async (
        params: OwnerShareParams
        ) => {
        try {
            const result: any = await GetInvestorFinancing(
                params
            );
            if (result.result) {
                financialData.value = {
                    ...transformUnit(result.returnValue),
                    capacity: Lodash.divide(params.capacity, 1000)
                };
            }
        } catch (error) {
            throw error;
        }
    };
    
    
    const stationProfitData = ref(
        [
            { year: 2020, profit1: 10, profit2: 11 },
            { year: 2021, profit1: 10, profit2: 11 },
            { year: 2022, profit1: 10, profit2: 11 },
            { year: 2023, profit1: 10, profit2: 11 },
            { year: 2024, profit1: 10, profit2: 11 },
            { year: 2025, profit1: 10, profit2: 11 },
            { year: 2026, profit1: 10, profit2: 11 },
            { year: 2027, profit1: 10, profit2: 11 },
            { year: 2028, profit1: 10, profit2: 11 },
            { year: 2029, profit1: 10, profit2: 11 },
            { year: 2030, profit1: 10, profit2: 11 },
        ]
    );

    /**
     * 获取十年收益
     * @param params 
     */
    const getStationProfitData = async (
        params: OwnerShareParams
        ) => {
        try {
            const result: any = await GetDecadeIncomeCount(
                params
            );
            if (result.result) {
               
            }
        } catch (error) {
            throw error;
        }
    };

    const stationProfitColumns = [
        {
            title: '年份',
            dataIndex: 'year',
            key: 'year',
        },
        {
            title: '峰谷价差收入（元）',
            dataIndex: 'profit1',
            key: 'profit1',
        },
        {
            title: '收入（元）',
            dataIndex: 'profit2',
            key: 'profit2',
        },
    ];

    const strategyData = ref([])

    const getStrategyData = async (params: OwnerShareParams) => {
        try {
            const result: any = await GetPvcrRealPrice(
                params
            );
            if (result.result) {
                const arr = [];
                for (let i = 0; i < result.returnValue.length; i++) {
                    // const date = `${dayjs(result.returnValue[i].pricingStartDate).format('YYYY-MM-DD')} ~ ${dayjs(result.returnValue[i].pricingEndDate).format('YYYY-MM-DD')}`;
                    const date = `${result.returnValue[i].seasonStr}(${result.returnValue[i].seasonRemark})`;
                    const obj = { 
                        chartComp: ref(null),
                        date: date,
                        dayIncome: Lodash.round(result.returnValue[i].dayIncome, 2),
                        seasonStr: result.returnValue[i].seasonStr,
                        seasonRemark: result.returnValue[i].seasonRemark,
                        priceTime: result.returnValue[i].dayPrice.priceTime,
                    };
                    arr.push(obj);
                }
                strategyData.value = arr;
            }
        } catch (error) {
            throw error;
        }
    };

    const cfdData = [
        {
            a: '谷',
            b: '8',
            c: '00:00',
            d: '03:00',
            e: 0.2,
            f: 0.15,
            g: '充满',
            h: '--',
            i: 12.5
        },
        {
            a: '谷',
            b: '8',
            c: '00:00',
            d: '03:00',
            e: 0.2,
            f: 0.15,
            g: '充满',
            h: '--',
            i: 12.5
        },
        {
            a: '谷',
            b: '8',
            c: '00:00',
            d: '03:00',
            e: 0.2,
            f: 0.15,
            g: '充满',
            h: '--',
            i: 12.5
        },
        {
            a: '谷',
            b: '8',
            c: '00:00',
            d: '03:00',
            e: 0.2,
            f: 0.15,
            g: '充满',
            h: '--',
            i: 12.5
        },
        {
            a: '谷',
            b: '8',
            c: '00:00',
            d: '03:00',
            e: 0.2,
            f: 0.15,
            g: '充满',
            h: '--',
            i: 12.5
        },
        {
            a: '谷',
            b: '8',
            c: '00:00',
            d: '03:00',
            e: 0.2,
            f: 0.15,
            g: '充满',
            h: '--',
            i: 12.5
        },
        {
            a: '谷',
            b: '8',
            c: '00:00',
            d: '03:00',
            e: 0.2,
            f: 0.15,
            g: '充满',
            h: '--',
            i: 12.5
        },
    ];
    
    const cfdColumns = [
        {
            title: '时段',
            dataIndex: 'a',
            key: 'a',
        },
        {
            title: '月份',
            dataIndex: 'b',
            key: 'b',
        },
        {
            title: '开始时间',
            dataIndex: 'c',
            key: 'c',
        },
        {
            title: '结束时间',
            dataIndex: 'd',
            key: 'd',
        },
        {
            title: '时段长（天）',
            dataIndex: 'e',
            key: 'e',
        },
        {
            title: '各时段长（天）',
            dataIndex: 'f',
            key: 'f',
        },
        {
            title: '充电状态',
            dataIndex: 'g',
            key: 'g',
        },
        {
            title: '放电状态',
            dataIndex: 'h',
            key: 'h',
        },
        {
            title: '各时段费用（元）',
            dataIndex: 'i',
            key: 'i',
        },
    ];

    const userForm = ref<CustomerInfo>(
        {
            nickName: '',
            telPhone: '',
            code: '',
            email: '',
            capacity: 0.233,
            address: '',
        }
    );

    const saveCustomerData = async (params: 
        CustomerInfo
    ) => {
        try {
            const result: any = await SaveCustomerInfo(
                params
            );
            return result.result;
        } catch (error) {
            throw error;
        }
    };

    
    return {
        financialData,
        getOwnerShareData,
        getOwnerAutarkyData,
        getOwnerAutarkyAndFinancingData,
        getInvestorNotFinancingData,
        getInvestorFinancingData,
        stationProfitData,
        getStationProfitData,
        stationProfitColumns,
        strategyData,
        getStrategyData,
        cfdData,
        cfdColumns,
        saveCustomerData,
        userForm,
    }
}