import { GetPvcrPriceAreaList, GetPvcrRealPrice } from "@/api/package/home";
import { CascaderProps, message } from "ant-design-vue";
import dayjs from "dayjs";
import { Ref, computed, nextTick, ref } from "vue";
import Lodash from 'lodash';
import Moment from 'moment';

enum EVolLevel {
    /**
     * 不满1KV
     */
    Leve1kV = 0,
    /**
     * 1-10千伏
     */
    Leve10kV = 1,
    /**
     * 20千伏及以上
     */
    Leves20kV = 2,
    /**
     * 10(20)千伏
     */
    Leve20kV = 3,
    /**
     * 35千伏及以上
     */
    Leve35kV = 4,
    /**
     * 35-110千伏
     */
    Leve110kV = 5,
    /**
     * 110千伏及以上
     */
    Leves110kV = 6,
    /**
     * 220千伏及以上
     */
    Leve220kV = 7,
}

enum EKwhType {
    /**
     * 一般工商业用电
     */
    Industry = 0,
    /**
    * 大工业用电
    */
    BigIndustry = 1,
    /**
   * 工商业及其他用电
   */
    TwoPartTariff = 2,
}

export interface FormState {
    /**
     * 地区
     */
    area: string[];
    /**
     * 用电类型
     */
    kwhType: EKwhType,
    /**
     * 输入电压
     */
    volLevel: EVolLevel;
    /**
     * 投资方收益占比
     */
    investorProfitRatio: number;
    /**
     * 业主方收益占比
     */
    ownerProfitRatio: number;
    /**
     * 年耗电量
     */
    yearConsumption: number;
    /**
     * 自投比例
     */
    selfInvestRatio: number;
    /**
     * 充放电深度
     */
    depth: number;
    /**
     * 装机容量
     */
    capacity: number;
    /**
     * 业主方分成占比
     */
    ownerRatio: number;
    /**
     * 充电效率
     */
    chargeEfficiency: number;
    /**
     * 放电效率
     */
    dischargeEfficiency: number;
    /**
     * 项目周期
     */
    yearSta: number;
    /**
     * 单价
     */
    price: number;
    /**
     * 融资比例
     */
    financeScale: number;
    /**
     * 贷款利率
     */
    financeInterest: number;
    /**
     * 贷款年限
     */
    loanTerm: number;
    /**
     * 日充放电循环次数
     */
    cycles: number;
    /**
     * 业主投资方案
     * 0:不投资，只享受收益分成  1:自行投资运营
     */
    ownerInvestmentPlan: number;
    /**
     * 投资方投资方案
     * 0: 不融资，业主分成    1:融资，业主分成
     */
    investorInvestmentPlan: number;
}

export const useForm = () => {

    const capacityVisible = ref(false);
    const editCapacity = ref(0);
    const saveCapacity = () => {
        if (editCapacity.value > 0 && editCapacity.value < 10000) {
            formState.value.capacity = editCapacity.value;
            capacityVisible.value = false;
            return false;
        }
        message.warning('装机容量应介于0～10000之间');
    }
    const showEditCapacity = () => {
        editCapacity.value = formState.value.capacity;
        capacityVisible.value = true;
    };
    const hideEditCapacity = () => {
        editCapacity.value = 0;
        capacityVisible.value = false;
    };


    const financeInterestVisible = ref(false);
    const editFinanceInterest = ref(0);
    const saveFinanceInterest = () => {
        if (editFinanceInterest.value > 0 && editFinanceInterest.value < 100) {
            formState.value.financeInterest = editFinanceInterest.value;
            financeInterestVisible.value = false;
            return false;
        }
        message.warning('融资利率应介于0～100之间');
    }
    const showEditFinanceInterest = () => {
        editFinanceInterest.value = formState.value.financeInterest;
        financeInterestVisible.value = true;
    };
    const hideEditFinanceInterest = () => {
        editFinanceInterest.value = 0;
        financeInterestVisible.value = false;
    };


    // 城市选项
    const cascaderVisible = ref(false);
    const showCascader = () => {
        cascaderVisible.value = true;
    }
    const hideCascader = () => {
        cascaderVisible.value = false;
    }
    const handleCascaderFinished = (v) => {
        const selectedOptions = v.selectedOptions;
        formState.value.area[0] = selectedOptions[0].value;
        formState.value.area[1] = selectedOptions[1].value;
        cascaderVisible.value = false;
    }
    const areaList: Ref<CascaderProps['options']> = ref([]);
    // END

    // 选择控件
    const pickerVisible = ref(false);
    const currentObj = ref<
    {
        field: string;
        columns: {
            text: string,
            value: number | string,
        }[],
        finish: (value: any) => void
    }>();

    const confirmPicker = (v) => {
        pickerVisible.value = false;
        currentObj.value?.finish(v);
    }

    const hidePicker = () => {
        pickerVisible.value = false;
    }

    const showPicker = () => {
        pickerVisible.value = true;
    }

    // END

    // 业主-分成比例
    const showOwnerRadtio = () => {
        currentObj.value = {
            field: 'ownerRatio',
            columns: [
                {
                    text: '10%',
                    value: 10,
                },
                {
                    text: '15%',
                    value: 15,
                },
                {
                    text: '20%',
                    value: 20,
                },
            ],
            finish: (v) => {
                formState.value.ownerRatio = v.selectedValues[0];
            }
        };
        showPicker();
    }

    // 业主-融资比例
    const showOwnerInvestmentPlan = () => {
        currentObj.value = {
            field: 'financeScale',
            columns: [
                {
                    text: '0%(不融资)',
                    value: 0,
                },
                {
                    text: '10%',
                    value: 0.1,
                },
                {
                    text: '20%',
                    value: 0.2,
                },
                {
                    text: '30%',
                    value: 0.3,
                },
                {
                    text: '40%',
                    value: 0.4,
                },
                {
                    text: '50%',
                    value: 0.5,
                },
                {
                    text: '60%',
                    value: 0.6,
                },
                {
                    text: '70%',
                    value: 0.7,
                },
                {
                    text: '80%',
                    value: 0.8,
                },
                {
                    text: '90%',
                    value: 0.9,
                },
            ],
            finish: (v) => {
                formState.value.financeScale = v.selectedValues[0];
            }
        };
        showPicker();
    }

    // 用电类型
    const changeKwhType = (v: number) => {
        formState.value.kwhType = v;
    }

    // 输入电压
    const changeVolLevel = (v: number) => {
        formState.value.volLevel = v;
    }
   
    // 切换业主投资方案
    const changeOwnerInvestPlan = (v: number) => {
        formState.value.ownerInvestmentPlan = v;
    }

    // 切换投资方投资方案
    const changeInvestorInvestPlan = (v: number) => {
        formState.value.investorInvestmentPlan = v;
    }

    const formState = ref<FormState>();

    const resetFormState = () => {
        formState.value = {
            area: [],
            volLevel: EVolLevel.Leve10kV, 
            kwhType: EKwhType.Industry,
            investorProfitRatio: 85,
            ownerProfitRatio: 15,
            yearConsumption: 0,
            selfInvestRatio: 100,
            depth: 90,
            capacity: 0.233,
            chargeEfficiency: 92.8,
            dischargeEfficiency: 92.8,
            yearSta: 10,
            price: 1.45,
            financeScale: 0.7,
            financeInterest: 4.2,
            loanTerm: 3,
            cycles: 2,
            ownerRatio: 10,
            ownerInvestmentPlan: 0,
            investorInvestmentPlan: 0,
        };
    }

    resetFormState();

    const getAreaList = async () => {
        try {
            const result: any = await GetPvcrPriceAreaList();
            // const result = {
            //     "returnValue": [
            //         {
            //             "province": "广东",
            //             "cityList": [
            //                 "广州",
            //                 "珠海"
            //             ]
            //         }
            //     ],
            //     "valueTypeName": null,
            //     "result": true,
            //     "errorMessage": "",
            //     "description": ""
            // };
            if (result.result) {
                const data = result.returnValue;
                if (!Array.isArray(data)) return;
                const options: CascaderProps['options'] = [];
                for (let i = 0; i < data.length; i++) {
                    const option = {
                        text: data[i].province,
                        label: data[i].province,
                        value: data[i].province,
                        children: []
                    };
                    options.push(
                        option
                    );
                    for (let j = 0; j < data[i].cityList.length; j++) {
                        option.children.push(
                            {
                                text: data[i].cityList[j],
                                label: data[i].cityList[j],
                                value: data[i].cityList[j]
                            }
                        );
                    }
                }
                areaList.value = options;
            }
        } catch (error) {
            throw error;
        }
    };


    const realPriceColumns = [
        {
            title: '时间段',
            dataIndex: 'date',
            key: 'date',
        },
        {
            title: '尖(元)',
            dataIndex: 'j',
            key: 'date',
        },
        {
            title: '峰(元)',
            dataIndex: 'f',
            key: 'date',
        },
        {
            title: '平(元)',
            dataIndex: 'p',
            key: 'date',
        },
        {
            title: '谷(元)',
            dataIndex: 'g',
            key: 'date',
        },
    ];

    const realPriceData = ref([
        // {
        //     key: 1,
        //     date: '2023-02-02~202303-03',
        //     j: 1.2,
        //     f: 1.89,
        //     p: 1.5,
        //     g: 1.0,
        //     max: 2
        // },
        // {
        //     key: 2,
        //     date: '2023-02-02~202303-03',
        //     j: 1.2,
        //     f: 1.9,
        //     p: 1.3,
        //     g: 0.3,
        //     max: 2
        // }
    ]);

    const getRealPrice = async (params: any) => {
        try {
            if (!formState.value.area.length) {
                return false;
            }
            const result: any = await GetPvcrRealPrice(
                params
            );
            if (result.result) {
                if (!(Array.isArray(result.returnValue) && !!result.returnValue.length)) {
                    message.info('查询不到电价信息');
                    realPriceData.value = [];
                    return false;
                }
                const arr = [];
                const arr0 = [];
                for (let i = 0; i < result.returnValue.length; i++) {
                    // const date = `${dayjs(result.returnValue[i].pricingStartDate).format('YYYY-MM-DD')} ~ ${dayjs(result.returnValue[i].pricingEndDate).format('YYYY-MM-DD')}`;
                    const date = `${result.returnValue[i].seasonStr}(${result.returnValue[i].seasonRemark})电价`;
                    const timeOfUsePrice = result.returnValue[i].timeOfUsePrice;

                    const priceTime = result.returnValue[i]?.dayPrice?.priceTime;
                    const durationMap = {
                        '尖': 0,
                        '峰': 0,
                        '平': 0,
                        '谷': 0,
                    };
                    for (let h = 0; priceTime && h < priceTime.length; h++) {
                        if (typeof durationMap[priceTime[h].priceTypeDesc] === 'number') {
                            durationMap[priceTime[h].priceTypeDesc] = durationMap[priceTime[h].priceTypeDesc] + priceTime[h].duration;
                        } else {
                            durationMap[priceTime[h].priceTypeDesc] = priceTime[h].duration;
                        }
                    }
                    const obj = {
                        dayIncome: Lodash.round(result.returnValue[i].dayIncome, 2),
                        seasonStr: result.returnValue[i].seasonStr,
                        seasonRemark: result.returnValue[i].seasonRemark,
                        key: i,
                        date,
                        j: 0,
                        f: 0,
                        p: 0,
                        g: 0,
                        jd: durationMap['尖'],
                        fd: durationMap['峰'],
                        pd: durationMap['平'],
                        gd: durationMap['谷'],
                        max: 0
                    };
                    const nameMap = {
                        '尖': 'j',
                        '峰': 'f',
                        '平': 'p',
                        '谷': 'g',
                    };
                    arr0.push(
                        {
                            ...obj,
                        }
                    );
                    let max = 0;
                    for (let j = 0; j < timeOfUsePrice.length; j++) {
                        max = timeOfUsePrice[j].price > max ? Lodash.ceil(timeOfUsePrice[j].price, 1) : max;

                    }
                    for (let j = 0; j < timeOfUsePrice.length; j++) {
                        obj[nameMap[timeOfUsePrice[j].name]] = Lodash.round(timeOfUsePrice[j].price, 4);
                        obj.max = max;
                    }
                    arr.push(obj);
                }
                realPriceData.value = arr;
            }
        } catch (error) {
            throw error;
        }
    };

    const existRealPrice = computed(
        () => {
            return Array.isArray(realPriceData.value) && !!realPriceData.value.length;
        }
    );

    const initForm = () => {
        getAreaList();
    }

    const areaStr = computed(
        () => {
            if (!formState.value.area.length) {
                return '请选择';
            }
            return formState.value.area.join('/');
        }
    );

    return {
        initForm,
        areaList,
        formState,
        resetFormState,
        getRealPrice,
        realPriceData,
        existRealPrice,
        realPriceColumns,
        cascaderVisible,
        showCascader,
        hideCascader,
        handleCascaderFinished,
        areaStr,
        changeOwnerInvestPlan,
        changeInvestorInvestPlan,
        pickerVisible,
        currentObj,
        confirmPicker,
        hidePicker,
        showOwnerRadtio,
        showOwnerInvestmentPlan,
        changeKwhType,
        changeVolLevel,
        capacityVisible,
        saveCapacity,
        showEditCapacity,
        hideEditCapacity,
        editCapacity,
        financeInterestVisible,
        editFinanceInterest,
        saveFinanceInterest,
        showEditFinanceInterest,
        hideEditFinanceInterest
    }

}