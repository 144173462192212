import { Dayjs } from "dayjs";

export interface PaginationRequest {
    pageSize: number;
    pageIndex: number;
}

export interface IStationEntity {
    id: number;
    name: string;
    address: string;
    currency: string;
    status: number;
    statusDesc: string;
    alarmCount: number;
    isCollect: boolean;
    capacity: number;
    p_unit: number;
    p_sun: number;
    p_INV: number;
    p_load: number;
    kwh_uti_in: number;
    kwh_sun: number;
    kwh_gen: number;
    kwh_INV_di: number;
    kwh_INV_chg: number;
    kwh_load: number;
    total_kwh_uti_in: number;
    total_kwh_uti_out: number;
    total_kwh_sun: number;
    total_kwh_gen: number;
    total_kwh_INV_di: number;
    total_kwh_INV_chg: number;
    total_kwh_load: number;
    soc: number;
    t_amb: number;
    todayProfit: number;
    totalProfit: number;
    [key: string]: number | string | number[] | boolean;
}

/**
 * 电站数据统计
 */
export interface IStationTrendEntity {
    timestamp: string;
    p_uti: number;
    p_sun: number;
    p_INV: number;
    p_load: number;
    kwh_uti_in: number;
    kwh_sun: number;
    kwh_gen: number;
    kwh_INV_di: number;
    kwh_INV_chg: number;
    kwh_load: number;
    total_kwh_uti_in: number;
    total_kwh_uti_out: number;
    total_kwh_sun: number;
    total_kwh_gen: number;
    total_kwh_INV_di: number;
    total_kwh_INV_chg: number;
    total_kwh_load: number;
    soc: number;
    t_amb: number;
    [key: string]: number | string;
}

export interface IEquipmentEntity {
    id: number;
    name: string;
    sn: string;
    type: number;
    mode: number;
    p_uti: number;
    p_sun: number;
    p_INV: number;
    p_load: number;
    kwh_uti_in: number;
    kwh_uti_out: number;
    kwh_sun: number;
    kwh_gen: number;
    kwh_INV_di: number;
    kwh_INV_chg: number;
    kwh_load: number;
    total_kwh_uti_in: number;
    total_kwh_uti_out: number;
    total_kwh_sun: number;
    total_kwh_gen: number;
    total_kwh_INV_di: number;
    total_kwh_INV_chg: number;
    total_kwh_load: number;
    soc: number;
    cd_alm: number;
    ent: number;
}

/**
 * 设备模式
 */
export enum EEquipmentMode {
    /**
     * 自发自用
     */
    zfzy = 1,

    /**
     * 削峰填谷
     */
    xftg = 2,

    /**
     * 电网优先
     */
    dwyx = 3,

    /**
     * 等待备电
     */
    ddbd = 4,
}

/**
 * 设备类型
 */
export enum EEquipmentType {
    /**
     * 通讯模块
     */
    Communication = 1,

    /**
     * 逆变器
     */
    Inv = 2,

    /**
     * 电表
     */
    ElectricityMeter = 3,
}

/**
 * 国家信息（包含省市）
 */
export interface IFullCountry {
    id: number;
    zhName: string;
    enName: string;
    provinces?: IFullProvince[];
    children?: IFullProvince[];
}

export interface IFullProvince {
    id: number;
    zhName: string;
    enName: string;
    cities?: IFullCity[];
    children?: IFullCity[];
}

export interface IFullCity {
    id: number;
    zhName: string;
    enName: string;
    latitude: number;
    longitude: number;
    stationCount: number;
}

export interface IStationInfo {
    stationId?: number;
    name: string;
    timeZone: number;
    capacity: number;
    startTime: string;
    currency: string;
    gridPrice: number;
    address: string;
    longitude: string;
    latitude: string;
    countryId: number;
    provinceId: number;
    cityId: number;
    ownerName: string;
    ownerMobile: string;
    ownerEmail: string;
    ownerPassword: string;
}

export interface IEquipmentInfo {
    id?: number;
    sn: string;
    name: string;
    description: string;
    type: number;
    power: number;
    capacity: number;
    version: string;
}

/**
 * 实时数据-电池
 */
export interface IBaterryRtData {
    sn: string;
    soc: number;
    soh: number;
    batNum: number;
    detail: {
        batNum: number; 
        cellVolt: number; 
        cellTemp: number;
    }[]
}

/**
 * 实时数据-逆变器
 */
export interface IInvRtData {
    equipmentId: number;
    equipmentSN: string;
    pV1_Volt: number;
    pV1_Curr: number;
    pV1_Pwr: number;
    pV2_Volt: number;
    pV2_Curr: number;
    pV2_Pwr: number;
    gridA_V: number;
    gridB_V: number;
    gridC_V: number;
    gridA_C: number;
    gridB_C: number;
    gridC_C: number;
    grid_S: number;
    grid_P: number;
    grid_Q: number;
    invA_V: number;
    invB_V: number;
    invC_V: number;
    invA_C: number;
    invB_C: number;
    invC_C: number;
    inv_S: number;
    inv_P: number;
    inv_Q: number;
    loadA_V: number;
    loadB_V: number;
    loadC_V: number;
    loadA_C: number;
    loadB_C: number;
    loadC_C: number;
    load_S: number;
    load_P: number;
    load_Q: number;
}

/**
 * 参数-工作模式
 */
export interface IWorkingParamData {
    id: number;
    equipmentSN: string;
    runingMode: number;
    selfMinSoc: number;
    workday: any[];
    weekend: any[];
}

/**
 * 参数-设备开关量
 */
export interface IEquipmentSwitchParamData {
    id: number;
    equipmentSN: string;
    drm: number;
    reset: number;
    arc: number;
    bat: number;
    extCtrl: number;
    island: number;
    pid: number;
    tempComp: number;
    batActive: number;
    isulate: number;
    leakCurr: number;
    relay: number;
    autoStart: number;
}

/**
 * 参数-电网
 */
export interface IGridParamData {
    id: number;
    equipmentSN: string;
    std: number;
    ratedVolt: number;
    ratedFreq: number;
    chEnable: number;
    antiReflux: number;
    refluxPwr: number;
    factor: number;
    gridVoltPoint: number;
    recvTime: number;
    speed: number;
}

/**
 * 参数-外设
 */
export interface IPeripheralParamData {
    id: number;
    equipmentSN: string;
    sensor: number;
    ctRatio: number;
    meterAddr: number;
    meterCompany: number;
    batCompany: number;
    batComm: number;
    batType: number;
    mppT_ctrl: number;
    mppT_para: number;
    mppT_num: number;
    mppT_pwr: number;
    mppT_step: number;
    mppT_cvt: number;
}

/**
 * 参数-逆变器
 */
export interface IInvParamData {
    id: number;
    equipmentSN: string;
    parallNum: number;
    capacity: number;
    stopVolt: number;
    chRate: number;
    equVolt: number;
    floatVolt: number;
    overVolt: number;
    underVolt: number;
    maxChCurr: number;
    maxDischCurr: number;
    maxChPwr: number;
    maxDischPwr: number;
    tempComp: number;
}

export interface IAlarmParamData {
    pageIndex: number;
    pageSize: number;
    queryText?: string;
    startTime?: string;
    endTime?: string;
    range?: number;
    countryId?: number;
    provinceId?: number;
    cityId?: number;
    equipmentSN?: string;
    sort?: {
        column?: string;
        sort?: number;
    };
}

/**
 * 告警
 */
export interface IBaseAlarmData {
    id: number;
    equipmentSN: string;
    equipmentName: string;
    alarmCode: string;
    alarmTime: string;
    restoreTime: string;
    status: number;
}

/**
 * 时区
 */
export interface ITimeZone {
    id: number;
    name: string;
}

export interface ICurrency {
    id: number;
    zhName: string;
    enName: string;
    character: string;
    value: string;
}

/**
 * 业主
 */
export interface IOwner {
    ownerId?: number;
    stationId?: number;
    ownerName: string;
    ownerMobile: string;
    ownerEmail: string;
    ownerPassword: string;
}

/**
 * 电站实时数据
 */
export interface IStationBasicInfo {
    id: number;
    name: string;
    address: string;
    currency: string;
    cityId: number;
    status: number;
    statusDesc: string;
    alarmCount: number;
    isCollect: boolean;
    p_uti: number;
    p_sun: number;
    p_INV: number;
    p_load: number;
    kwh_uti_in: number;
    kwh_sun: number;
    kwh_gen: number;
    kwh_INV_di: number;
    kwh_INV_chg: number;
    kwh_load: number;
    total_kwh_uti_in: number;
    total_kwh_uti_out: number;
    total_kwh_sun: number;
    total_kwh_gen: number;
    total_kwh_INV_di: number;
    total_kwh_INV_chg: number;
    total_kwh_load: number;
    soc: number;
    t_amb: number;
    todayProfit: number;
    totalProfit: number;
    owners: IOwner[];
    [key: string]: number | string | boolean | IOwner[];
}

export interface IStationDetail {
    stationId: number;
    name: string;
    address: string;
    currency: string;
    status: number;
    statusDesc: string;
    alarmCount: number;
    isCollect: boolean;
    timeZone: number;
    startTime: string | Dayjs;
    capacity: number;
    gridPrice: number;
    bmsPrice: number;
    longitude: number;
    latitude: number;
    countryId: number;
    provinceId: number;
    cityId: number;
    owers: IOwner[];
    area?: number[];
    lnglat?: string;
    ownerName?: string;
    ownerAccount?: string;
    ownerEmail?: string;
    ownerPassword?: string;
}
