import { createApp } from 'vue';
import router from './router';
import store from './store';
import directives from './directives';
import App from './App.vue';
import VueI18n from './locales'
import '@/assets/icons/index.js';
import '@/assets/stylesheets/global.scss';
import 'ant-design-vue';
// import 'ant-design-vue/dist/antd.css';
import 'ant-design-vue/dist/antd.less';
import 'vant/lib/index.css';

createApp(App).use(store).use(router).use(directives).use(VueI18n).mount('#app');
