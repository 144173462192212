import dayjs from "dayjs";

/**
 * 时间戳转时间字符串
 */
export function formatDate(value: number | string, formatStr: string = 'YYYY-MM-DD HH:mm:ss') {
    return dayjs(value).format(formatStr);
}

const getENVIR = () => {
  const ua = navigator.userAgent.toLowerCase() as any;
  const isWeixin = ua.indexOf('micromessenger') !== -1;
  const isInApp = /(^|;\s)app\//.test(ua);
  if (isWeixin) {
    if ((window as any).__wxjs_environment === 'miniprogram') {
      return 'wxapp';
    } else {
      return 'wxh5';
    }
  } else {
    if (!isInApp) {
      return 'browser';
    } else {
      return 'app';
    }
  }
}

export function isANDROID() {
    return (navigator.userAgent.indexOf('Android') > -1 || navigator.userAgent.indexOf('Adr') > -1);
} 

/**
 * 微信h5
 */
export function isWxH5() {
  return  getENVIR() ===  'wxh5';
}

/**
 * 微信小程序
 */
export function isWxAPP() {
  return  getENVIR() ===  'wxapp';
}