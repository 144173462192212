import { Launcher } from '@~crazy/launcher';

import store from '@/store';
import router from '@/router';

import { message } from 'ant-design-vue';
import i18n from '@/locales';

const { VUE_APP_RequestBaseURL, VUE_APP_WithCredentials } = process.env;

export const launcher = new Launcher({
	baseUrl: VUE_APP_RequestBaseURL,
	// credentials: VUE_APP_WithCredentials === 'true',
	timeoutAgain: true,
	requestOptions: {
		headers: {},
	},
	beforeHandler: () => {
		launcher.setAuthorization(`Bearer ${store.state.auth.ticket}`);
	},
	authHandler: (code) => {
		if (code == 401) {
			message.error(i18n.global.t('UnvalidTokenTip'));
			store.dispatch('Logout');
			router.push('/login');
		} else if (code == 403) {
			message.error(i18n.global.t('UnvalidTokenTip'));
			store.dispatch('Logout');
			router.push('/login');
		}
	},
	fail: (error: any) => {
		if (error.code !== 200) {
			const CodeTextMap: {
				[key: number]: string
			} = {
				300: i18n.global.t('common.TheBusinessIsAbnomal'),
				310: i18n.global.t('common.TheCountryIsAbnomal'),
				311: i18n.global.t('common.TheCountryNotExists'),
				320: i18n.global.t('common.TheProvinceIsAbnomal'),
				321: i18n.global.t('common.TheProvinceNotExists'),
				330: i18n.global.t('common.TheCityIsAbnomal'),
				331: i18n.global.t('common.TheCityNotExists'),
				340: i18n.global.t('common.TheFileIsAbnormal'),
				341: i18n.global.t('common.FileFormatIsIncorrect'),
				400: i18n.global.t('common.ParameterIsIncorrect'),
				401: i18n.global.t('common.UserTokenIsInvalidAccessIsProhibited'),
				403: i18n.global.t('common.UserTokenIsInvalidAccessIsProhibited'),
				404: i18n.global.t('common.NotFound'),
				405: i18n.global.t('common.HadDeleted'),
				406: i18n.global.t('common.ExistAlready'),
				440: i18n.global.t('common.VerificationCodeIsIncorrect'),
				441: i18n.global.t('common.VerificationCodeIsExpired'),
				442: i18n.global.t('common.TheAcquisitionOfVerificationCodeIsTooFrequently'),
				450: i18n.global.t('common.TheAccountIsAbnormal'),
				451: i18n.global.t('common.TheAccountWasRegisteredAlready'),
				452: i18n.global.t('common.TheAccountNotExists'),
				460: i18n.global.t('common.TheMobileIsAbnormal'),
				461: i18n.global.t('common.TheMobileWasRegisteredAlready'),
				462: i18n.global.t('common.TheMobileNotExists'),
				470: i18n.global.t('common.TheEmailIsAbnormal'),
				471: i18n.global.t('common.TheEmailWasRegisteredAlready'),
				472: i18n.global.t('common.TheEmailNotExists'),
				480: i18n.global.t('common.PasswordIsIncorrect'),
				481: i18n.global.t('common.OriginalPasswordIsIncorrect'),
				500: i18n.global.t('common.ServiceError'),
			};
			const tip = CodeTextMap[error.code] || error.message;
			message.error(tip);
		}
	},
	// timeoutHandler: (options, again) => {
	// 	let msg = `请求接口'${options.url}'超时`;
	// 	if (again) {
	// 		msg += ',正在重新发起请求';
	// 	}
	// 	message.error(msg);
	// 	console.error(msg);
	// },
});

/**
 * 请求服务器静态文件
 * @param url 路径
 * @returns 
 */
export const fetchStatisticFile = (
	url: string,
) => {
	const options = {
		method: 'get',
		headers: {
			'Accept': 'application/json',
			'Content-Type': 'application/x-www-form-urlencoded',
		},
	};
	const fetchPromise = new Promise(
		async (resolve, reject) => {
			try {
				const res = await fetch(url, options);
				if (res.status == 200) {
					const data = await res.json();
					console.log(data);
					resolve(data);
				} else {
					throw new Error('接口请求错误');
				}
			} catch (error) {
				reject(error);
			}
		}
	);
	const abortPromise = new Promise(
		(resolve, reject) => {
			setTimeout(
				() => {
					reject(new Error('接口请求超时'));
				},
				10000
			);
		}
	);
	return Promise.race(
		[
			fetchPromise,
			abortPromise,
		]
	);
}

export * as Interface from './interface';
export * as DefaultResponse from './defaultResponse';
export * from './package';
export * as Type from './type';