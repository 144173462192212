import { computed, onMounted, ref } from "vue"
import Stickyfill from 'stickyfilljs';

export const useHome = (realPriceData: any) => {
    const tab = ref(null);
    const priceBody = ref(null);

    const init = () => {
        if ( !('CSS' in window && CSS.supports && CSS.supports('position', 'sticky')) ) {
            Stickyfill.add(tab.value);
        }
    }
    onMounted(
        () => {
            init();
        }
    );

    const slideOut = ref(false);
    let dragging = false;
    const start = {
        x: 0,
        y : 0
    };
    const startDrag = (e) => {
        e = e.changedTouches ? e.changedTouches[0] : e;
        dragging = true;
        start.x = e.pageX;
        start.y = e.pageY;
      };
    const onDrag = (e) => {
        e = e.changedTouches ? e.changedTouches[0] : e;
        if (dragging) {
        //   console.log(e.pageY - start.y);
        }
      };
    const stopDrag = (e) => {
        e = e.changedTouches ? e.changedTouches[0] : e;
        if (dragging) {
          dragging = false;
          if (!slideOut.value) {
            if (e.pageY - start.y < -10) {
                slideOut.value = true;
            }
          } else {
            if (e.pageY - start.y > 10) {
                slideOut.value = false;
                priceBody.value?.scrollTo(0, 0);
            }
          }
        }
    }


    const slideClick = () => {
        slideOut.value = !slideOut.value;
    }

    const bodyOverflowYAuto = ref(false);

    const setBodyOverflowYAutoTimeout = () => {
        const t = setTimeout(
            () => {
                bodyOverflowYAuto.value = true;
                clearTimeout(t);
            },
            600
        );
    }

    const bodyWrapperStyle = computed(
        () => {
            if (bodyOverflowYAuto.value) {
                return 'body-wrapper body-wrapper-overflow-auto';
            }
            return 'body-wrapper';
        }
    );

    const priceContainerStyle = computed(
        () => {
            const len = realPriceData.value?.length;
            const bodyStyle = len > 2 ? 'bodyFullHeight' : `bodyHeight${len}`;
            if (slideOut.value) {
                setBodyOverflowYAutoTimeout();
                return `price-container price-container-out ${bodyStyle}`
            } 
            return 'price-container';
        }
    );

    return {
        tab,
        startDrag,
        onDrag,
        stopDrag,
        slideClick,
        priceContainerStyle,
        priceBody,
        bodyWrapperStyle,
    }
}