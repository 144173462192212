import { createI18n } from "vue-i18n";

import zhCN from "./languages/zhCN";

import enUS from "./languages/enUS";

const i18n = createI18n({
    legacy: false,
    locale: "zh",
    messages: {
        zh: zhCN,
        en: enUS, 
    },
});

export default i18n;
