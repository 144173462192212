export default {
    common: {
        "Account": "Account",
        "Logout": "Log Out",
        "Phone": "Phone",
        "Email": "Email",
        "PleaseEnter": "Please Enter",
        "PleaseChoose": "Please Choose",
        "Password": "Password",
        "LogOutSucceeded": "Log Out Succeeded",
        "LoginSucceeded": "Login Succeeded",
        "LoginFailed": "Login Failed",
        "SignUp": "Sign Up",
        "Login": "Log in",
        "Rmb": "RMB",
        "HomePage": "Home Page",
        "Day": "Day",
        "Month": "Month",
        "Year": "Year",
        "Customize": "Customize",
        "Total": "Total",
        "Station": "Station",
        "Equipment": "Equipment",
        "All": "All",
        "SwitchLanguageTip": "Switch to {name}",
        "Cancel": "Cancel",
        "Save": "Save",
        "Operation": "Operation",
        "Refresh": "Refresh",
        "Add": "Add",
        "Update": "Update",
        "Delete": "Delete",
        "Release": "Release",
        "Bind": "Bind",
        "Succeeded": "Succeeded",
        "Failed": "Failed",
        "RequestErrorTip": "Interface request failed, please contact the administrator",
        "SetUp": "Set Up",
        "UpdateSucceeded": "Update Succeeded",
        "UpdateFailed": "Update Failed",
        "RefreshSucceeded": "Refresh Succeeded",
        "RefreshFailed": "Refresh Failed",
        "Emailformaterror": "Email format error",
        "Phoneformaterror": "Phone format error",
        "UnvalidTokenTip": "Unvalid token, please log in again",
        "Close": "Close",
        "NoDataAvailable": "No Data Available",
        "TheBusinessIsAbnomal": "Business Is Abnomal",
        "TheCountryIsAbnomal": "Country Is Abnomal",
        "TheCountryNotExists": "The country not exists",
        "TheProvinceIsAbnomal": "The province is abnomal",
        "TheProvinceNotExists": "The province not exists",
        "TheCityIsAbnomal": "The city is abnomal",
        "TheCityNotExists": "The city not exists",
        "TheFileIsAbnormal": "The file is abnormal",
        "FileFormatIsIncorrect": "File format is incorrect",
        "ParameterIsIncorrect": "Parameter is incorrect",
        "UserTokenIsInvalidAccessIsProhibited": "User Token is invalid,Access is prohibited",
        "NotFound": "Not Found",
        "HadDeleted": "Had deleted",
        "ExistAlready": "Exist Already",
        "VerificationCodeIsIncorrect": "Verification Code is incorrect",
        "VerificationCodeIsExpired": "Verification Code is expired",
        "TheAcquisitionOfVerificationCodeIsTooFrequently": "The Acquisition of Verification Code is too frequently",
        "TheAccountIsAbnormal": "The account is abnormal",
        "TheAccountWasRegisteredAlready": "The account was registered already",
        "TheAccountNotExists": "The account not exists",
        "TheMobileIsAbnormal": "The mobile is abnormal",
        "TheMobileWasRegisteredAlready": "The mobile was registered already",
        "TheMobileNotExists": "The mobile not exists",
        "TheEmailIsAbnormal": "The email is abnormal",
        "TheEmailWasRegisteredAlready": "The email was registered already",
        "TheEmailNotExists": "The email not exists",
        "PasswordIsIncorrect": "Password is incorrect",
        "OriginalPasswordIsIncorrect": "Original Password is incorrect",
        "ServiceError": "Service Error",
        "TestingForEmail": "Testing for Email",
        "TestingForMobile": "Testing for Mobile",
        "Unbound": "unbound",
        "BindPhone": "Bind Phone",
        "BindSuccess": "Bind Success",
        "BindEmail": "Bind Email",
    },
    login: {
        "Welcome": "Welcome!",
        "SystemName": "Kortrong Household Savings Cloud",
    },
    largeScreen: {
        "StationStatistics": "Station Statistics",
        "DataStatistics": "Data Statistic",
        "RealtimeAlarm": "Real-time Alarm",
        "MapTips": "Click to switch regions, double-click to drill down",
    },
    alarm: {
        "DischargeOvercurrent": "Discharge Over Current",
        "OverloadAlarm": "Overload Alarm",
        "BatteryNotConnected": "Battery Not Connected",
        "BatteryUndervoltage": "Battery Under Voltage",
        "LowBatteryVoltage": "Low Battery Voltage",
        "HighBatteryVoltage": "High Battery Voltage",
        "LowGridFrequency": "Low Grid Frequency",
        "HighGridFrequency": "High Grid Frequency",
        "LeakageCurrent": "Leakage Current",
        "CanCommunicationFailureDuringStartup": "Can Communication Failure During Start up",
        "ExternalCTConnectedInversely": "External CT Connected Inversely",
        "LowBUSVoltage": "Low BUS Voltage",
        "BusVoltageHigh": "Bus Voltage High",
        "InverterCurrent": "Inverter Current",
        "ChargingOvercurrentAlarm": "Charging Overcurrent Alarm",
        "BusVibrationAlarm": "Bus Vibration Alarm",
        "Normal": "Normal",
        "Alarm": "Alarm",
        "Fault": "Fault",
        "AlarmTriggered": "Alarm triggered",
        "NoAlarmDetected": "No alarm detected",
        "AlarmCleared": "Alarm Cleared", 
        "OccurrenceTime": "Occurrence Time", 
        "RecoveryTime": "Recovery Time", 
        "AlarmStatus": "Alarm Status",
        "Prefecture": "Region",
        "InAlarm": "In Alarm",
        "Restored": "Restored",
    },
    station: {
        "TotalIncome": "Total Income",
        "MonthIncome": "Month Income",
        "DailyIncome": "Daily Income",
        "ChargingPower": "Charging Power",
        "MainsPower": "Mains Power",
        "PVPower": "PV Power",
        "LoadPower": "Load Power",
        "TotalChargingCapacity": "Total Charging Capacity",
        "MonthChargingCapacity": "Month Charging Capacity",
        "DailyChargingCapacity": "Daily Charging Capacity",
        "TotalDischargingCapacity": "Total Discharging Capacity",
        "MonthDischargingCapacity": "Month Discharging Capacity",
        "DailyDischargingCapacity": "Daily Discharging Capacity",
        "TotalPowerGeneration": "Total Power Generation",
        "MonthPowerGeneration": "Month Power Generation",
        "DailyPowerGeneration": "Daily Power Generation",
        
        "Income": "Income",
        "ChargingCapacity": "Charging Capacity",
        "DischargingCapacity": "Discharging Capacity",
        "PowerGeneration": "Power Generation",

        "UnderConstruction": "Under Construction",
        "Operating": "Operating",
        "Cancelled": "Cancelled",
        "Outage": "Outage",

        "AllStations": "All Stations",
        "Followed": "My Followings",

        "StationName": "Station Name",
        "Prefecture": "Region",
        "Address": "Address",
        "StationStatus": "Station Status",
        "Search": "Search",
        "Reset": "Reset",
        "AddStation": "Add Station",
        "ViewAlarms": "View Alarms",
        "EditStation": "Edit Station",
        "Follow": "Follow",
        "Unfollow": "Unfollow",
        "StationDetails": "Station Details",
        "SureDelete": "Are you sure to delete \"{name}\"?",
        "SureRelease": "Are you sure to release the device\"{name}\"?",
        "Capacity": "Capacity",
        
        "BasicInformation": "Basic Information",
        "TimeZone": "Time Zone",
        "LatitudeAndLongitude": "Latitude and Longitude",
        "MapSelection": "Map Selection",
        "StartDate": "Start Date",
        "Currency": "Currency",
        "UnitPriceOfElectricity": "Unit Price Of Electricity",
        "OwnerInformation": "Owner Information",
        "OwnerName": "Owner Name",

        "RealtimeData": "Real-time Data",
        "ParameterSettings": "Parameter Settings",
        "Owner": "Owner",

    },
    equipment: {
        "SelfConsumption": "Self-consumption",
        "PeakShavingAndValleyFilling": "Peak Shaving and Valley Filling", 
        "PowerGridPriority": "Power grid priority",
        "WaitingForBackupPower": "Waiting for Backup Power",

        "EquipmentName": "Equipment Name",
        "EquipmentType": "Equipment Type",
        "RealtimePower": "Real-time Power",

        "TotalPowerGeneration": "Total Power Generation",
        "DailyPowerGeneration": "Daily Power Generation",

        "AlarmStatus": "Alarm Status",
        "EventStatus": "Event Status",
        "BindStation": "Bind Station",

        "Communicator": "Communicator",
        "Inverter": "Inverter",
        "ElectricityMeter": "Electricity Meter",

        "Normal": "Normal",
        "MainsOutage": "Mains Outage", 
        "StormWarning": "Storm Warning", 
        "EmergencyStop": "Emergency Stop", 
        "DischargetoReserveLimit": "Discharge to Reserve Limit", 
        "ResetSelfcheck": "Reset Self-check",
        "EquipmentFailure": "Equipment Failure", 

        "RealtimeData": "Real-time Data",
        "Grid": "Power Grid",
        "PV": "PV",
        "Load": "Load",
        "Battery": "Battery",
    },
    stationStatisticsParams: {
        "MainsPower": "Mains Power",
        "PVPower": "PV Power",
        "LoadPower": "Load Power",
        "InverterPower": "Inverter Power",
        "DailyInputElectricityofMains": "Daily Input Electricity of Mains",
        "DailyOutputElectricityofMains": "Daily Output Electricity of Mains",
        "DailyPowerSupplyofSolar": "Daily Power Supply of Solar",
        "DailyPowerSupplyofGenerator": "Daily Power Supply of Generator",
        "DailyDischargeCapacityofInverter": "Daily Discharge Capacity of Inverter",
        "DailyChargeCapacityofInverter": "Daily Charge Capacity of Inverter",
        "DailyPowerConsumptionofLoad": "Daily Power Consumption of Load",
        "TotalInputElectricityofMains": "Total Input Electricity of Mains",
        "TotalOutputElectricityofMains": "Total Output Electricity of Mains",
        "TotalPowerSupplyofSolar": "Total Power Supply of Solar",
        "TotalPowerSupplyofGenerator": "Total Power Supply of Generator",
        "TotalDischargeCapacityofInverter": "Total Discharge Capacity of Inverter",
        "TotalChargeCapacityofInverter": "Total Charge Capacity of Inverter",
        "TotalPowerConsumptionofLoad": "Total Power Consumption of Load",
        "SOC": "SOC",
        "EnvironmentTemperature": "Environment Temperature",
        "GridIncome": "Grid Income",
        "ESSIncome": "ESS Income", 
        "TotalIncome": "Total Income", 
    },
    equipmentRealtime: {
        "PhaseVoltageofGrid": "{name} Phase Voltage of Grid", 
        "PhaseCurrentofGrid": "{name} Phase Current of Grid", 
        "Voltage": "Voltage", 
        "Current": "Current", 
        "Power": "Power", 
        "Temperature": "Temperature",
        "PhaseVoltageofLoad": "{name} Phase Voltage of Load", 
        "PhaseCurrentofLoad": "{name} Phase Current of Load", 
        "PhaseVoltageofInverter": "{name} Phase Voltage of Inverter", 
        "PhaseCurrentofInverter": "{name} Phase Current of Inverter", 
        "Cell": "Cell {num} {type}",  
        "ApparentPowerofInverter": "Apparent Power of Inverter",
        "ActivePowerofInverter": "Active Power of Inverter", 
        "ReactivePowerofInverter": "Reactive Power of Inverter", 
    },
    equipmentParams: {
        "WorkingMode": "Working Mode",
        "DigitalSignal": "Digital Signal",
        "PowerGrid": "Power Grid",
        "PeripheralDevice": "Peripheral Device",
        "Inverter": "Inverter",
        "RetainSOC": "Retain SOC",
        "Weekday": "Weekday",
        "Weekend": "Weekend",
        "PriceStrategy": "Price Strategy({type})",
        "ValleyPeriod": "Valley Period", 
        "FlatPeriod": "Flat Period", 
        "PeakPeriod": "Peak Period", 
        "V": "V", 
        "F": "F", 
        "P": "P", 
        "DRMEnable": "DRM Enable", 
        "Enable": "Enable", 
        "Prohibit": "Prohibit", 
        "No": "No", 
        "Yes": "Yes", 
        "ArcDetection": "Arc Detection", 
        "BatteryAlarmFunctionEnable": "Battery Alarm Function Enable", 
        "ExternalControlModeEnable": "External Control Mode Enable", 
        "FactoryReset": "Factory Reset", 
        "ActiveIslandingModeEnable": "Active Islanding Mode Enable", 
        "AntiPIDEnable": "Anti-PID Enable", 
        "BatteryTemperatureCompensationEnable": "Battery Temperature Compensation Enable", 
        "BatteryActivationEnable": "Battery Activation Enable", 
        "InsulationImpedanceDetectionEnable": "Insulation Impedance Detection Enable", 
        "LeakageCurrentDetectionEnable": "Leakage Current Detection Enable", 
        "RelaySelfcheckEnable": "Relay Self-check Enable", 
        "AutoPowerOn": "Auto Power On", 
        "StandardType": "Standard Type", 
        "RatedGridVoltage": "Rated Grid Voltage", 
        "RatedGridFrequency": "Rated Grid Frequency", 
        "GridtoBatteryCharging": "Grid-to-Battery Charging", 
        "AntireverseFlowFunction": "Anti-reverse Flow Function", 
        "MaximumGridReversePower": "Maximum Grid Reverse Power", 
        "PowerFactor": "Power Factor", 
        "10MinuteGridVoltage": "10-Minute Grid Voltage", 
        "OngridRestorationTime": "On-grid Restoration Time",  
        "ActivePowerSoftStartRate": "Active Power Soft Start Rate",
        "China": "China",
        "Germany": "Germany",
        "MeteringSensorType": "Metering Sensor Type", 
        "ExternalCTRatio": "External CT Ratio",  
        "SmartMeterAddress": "Smart Meter Address", 
        "MeterManufacturer": "Meter Manufacturer", 
        "BatteryManufacturer": "Battery Manufacturer", 
        "BatteryCommunicationMethod": "Battery Communication Method", 
        "BatteryType": "Battery Type", 
        "MPPTControlMode": "MPPT Control Mode", 
        "MPPTParallelMode": "MPPT Parallel Mode", 
        "NumberofMPPTChannels": "Number of MPPT Channels", 
        "MPPTPower": "MPPT Power", 
        "MPPTPerturbationStepSize": "MPPT Perturbation Step Size", 
        "CVTVoltage": "CVT Voltage", 
        "NumberofBatteryCellsinParallel": "Number of Battery Cells in Parallel",  
        "BatteryConfiguredCapacity": "Battery Configured Capacity", 
        "DischargeCutoffVoltage": "Discharge Cut-off Voltage", 
        "ChargeRate": "Charge Rate", 
        "EqualizingChargeVoltage": "Equalizing Charge Voltage", 
        "FloatChargeVoltage": "Float Charge Voltage", 
        "OvervoltageProtectionPoint": "Overvoltage Protection Point", 
        "UndervoltageProtectionPoint": "Undervoltage Protection Point", 
        "MaximumChargingCurrent": "Maximum Charging Current",
        "MaximumDischargingCurrent": "Maximum Discharging Current", 
        "MaximumChargingPower": "Maximum Charging Power", 
        "MaximumDischargingPower": "Maximum Discharging Power", 
        "TemperatureCompensationCoefficient": "Temperature Compensation Coefficient", 
        "Non": "Non", 
        "SmartMeter": "Smart Meter", 
        "LeadacidBattery": "Lead-acid Battery", 
        "LithiumBattery": "Lithium Battery", 
        "DCPowerSource": "DC Power Source", 
        "Independent": "Independent", 
        "Inparallel": "In-parallel", 
        "SetUpDigitalSignal": "Set Up Digital Signal",
        "SetUpPowerGrid": "Set Up Power Grid",
        "SetUpPeripheralDevice": "Set Up Peripheral Device",
        "SetUpInverter": "Set Up Inverter",
        "SetUpWorkingMode": "Set Up Working Mode",
    },
};
